import { Link, useSearchParams } from 'react-router-dom';
import Logo from '@/components/Logo';
import Login from '@/components/user/Login';

export default function LoginPage({ redirectUrl }: { redirectUrl?: string }) {
  const searchParams = useSearchParams()[0];

  const redirect = searchParams.get('redirect');

  return (
    <div className="flex flex-col h-screen items-center justify-center md:bg-gray-100">
      <div className="flex flex-col items-center gap-4 bg-white p-4 md:rounded-sm md:border border-gray-200 w-80">
        <div className="flex justify-center items-center px-2 pb-4 h-28">
          <Logo />
        </div>
        <Login redirectUrl={redirect || redirectUrl} />
      </div>
      <div className="mt-8 mx-4 flex flex-col md:flex-row gap-6 items-center flex-wrap text-primary-900">
        <Link to="/informationspflichten">Informationspflichten</Link>
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutz">Datenschutz</Link>
      </div>
    </div>
  );
}
