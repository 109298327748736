import React from 'react';
import { formatDate, formatDateTime } from '@/lib/dateUtils';
import { formatNumber } from '@/lib/utils';
import { BBResult, PrettyBuchung } from '../../../../server/src/bookingsheet/bookingsheet';

function Kto({ kto, uk }: { kto: string; uk?: string }) {
  if (uk) {
    return (
      <>
        <span className="text-gray-400">{kto}</span>
        <br />
        {uk}
      </>
    );
  } else {
    return <>{kto}</>;
  }
}

function BookingRows({ rows }: { rows: PrettyBuchung[] }) {
  return (
    <>
      {rows.map(b => (
        <tr key={b.BU_KEY} className="border-b last:border-b-0 border-dashed">
          <td className="text-left px-2">{b.BU_KEY}</td>
          <td className="text-left px-2">
            {b.TYP.trim()}|{b.SOLLFUNKTION.trim()}|{b.HABENFUNKTION.trim()}
          </td>
          <td className="text-right px-2">{formatNumber(b.BETRAG)}</td>
          <td className="text-right px-2">{formatDate(b.GELTUNGSMONAT)}</td>
          <td className="text-right px-2">{formatDate(b.BELEGDATUM)}</td>
          <td className="text-right px-2">{formatDateTime(b.DATUMNEUANLAGE)}</td>
          <td className="text-left px-2">{b.BUCHUNGSTEXT}</td>
          <td className="text-left px-2">
            <Kto kto={b.SOLL} uk={b.SOLLUK} />
          </td>
          <td className="text-left px-2">
            <Kto kto={b.HABEN} uk={b.HABENUK} />
          </td>
        </tr>
      ))}
    </>
  );
}

const colSpan = 9;
export default function BookingsRaw({ data }: { data: BBResult }) {
  return (
    <div className="relative">
      <table className="table-auto text-xs">
        <thead className="sticky top-20 bg-gray-800 text-white">
          <tr className="font-bold">
            <th className="text-left px-2">BU_KEY</th>
            <th className="text-left px-2" title="TYP,SOLLFUNKTION,HABENFUNKTION">
              TYP
            </th>
            <th className="text-right px-2">BETRAG</th>
            <th className="text-right px-2">GELTUNGSM.</th>
            <th className="text-right px-2">BELEGD.</th>
            <th className="text-right px-2">DATUMN.</th>
            <th className="text-left px-2">BUCHUNGSTEXT</th>
            <th className="text-left px-2" title="TYP,FUNKTION,VERWENDUNG">
              SOLL
            </th>
            <th className="text-left px-2" title="TYP,FUNKTION,VERWENDUNG">
              HABEN
            </th>
          </tr>
        </thead>
        <tbody>
          {data.mieterData
            .filter(m => m.mietbuchungen.length > 0 || m.mietgegenbuchungen.length > 0)
            .map(m => (
              <React.Fragment key={m.mieter.PE_KEY}>
                <tr className="sticky top-24 bg-white">
                  <td className="font-bold bg-gray-200" colSpan={colSpan}>
                    Mietbuchungen: {m.mieter.KURZFORM}
                  </td>
                </tr>
                <BookingRows rows={m.mietbuchungen} />
                <tr className="sticky top-24 bg-white">
                  <td className="font-bold bg-gray-200" colSpan={colSpan}>
                    Mietgegenbuchungen: {m.mieter.KURZFORM}
                  </td>
                </tr>
                <BookingRows rows={m.mietgegenbuchungen} />
              </React.Fragment>
            ))}

          <tr className="sticky top-24 bg-white">
            <td className="font-bold bg-gray-200" colSpan={colSpan}>
              Sevbuchungen
            </td>
          </tr>
          <BookingRows rows={data.sevData.sevbuchungen} />

          <tr className="sticky top-24 bg-white">
            <td className="font-bold bg-gray-200" colSpan={colSpan}>
              Sevgegenbuchungen
            </td>
          </tr>
          <BookingRows rows={data.sevData.sevgegenbuchungen} />
        </tbody>
      </table>
    </div>
  );
}
