import { useEffect, useId, useState } from 'react';
import {
  CollapsePanelButton,
  CollapsePanel,
  CollapsePanelContent
} from '@/components/CollapsePanel';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { usePageSection } from '@/lib/apiEndpoints';
import { ContentSection } from '@/shared/types';

function FaqList({ data }: { data: ContentSection[] }) {
  const id = useId();
  const [openIdx, setOpenIdx] = useState(-1);

  useEffect(() => {
    setOpenIdx(-1);
  }, [data]);

  const toggleIdx = (idx: number) => {
    setOpenIdx(idx === openIdx ? -1 : idx);
  };

  return (
    <div className="flex flex-col flex-wrap">
      {data.map((elem, idx) => {
        const isOpen = idx === openIdx;
        const belowOpen = idx + 1 === openIdx;
        return (
          <CollapsePanelContent key={idx} isOpen={isOpen} belowOpen={belowOpen}>
            <CollapsePanelButton id={id} isOpen={isOpen} onClick={() => toggleIdx(idx)}>
              {elem.title}
            </CollapsePanelButton>
            <CollapsePanel isOpen={isOpen} scrollTo>
              <div
                aria-labelledby={id}
                dangerouslySetInnerHTML={{ __html: elem.content }}
                className="prose mb-4 ml-1"
              ></div>
            </CollapsePanel>
          </CollapsePanelContent>
        );
      })}
    </div>
  );
}

export default function FaqPage() {
  const { data, error } = usePageSection('faq');

  return (
    <PageContainer>
      <PageHeader>FAQ - Häufig gestellte Fragen</PageHeader>
      <QueryWrapper data={data} error={error}>
        {data => <FaqList data={data} />}
      </QueryWrapper>
    </PageContainer>
  );
}
