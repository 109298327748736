import { useState } from 'react';
import InfoCard from '@/components/InfoCard';
import MyServices from '@/components/MyServices';
import NewDocuments from '@/components/NewDocuments';
import Contact from '@/components/user/Contact';
import { UnitsCard } from '@/components/user/Units';
import { useBreakpoint } from '@/lib/hooks';

export default function IndexPage() {
  const [infoShown, setInfoShown] = useState(true);

  const isMd = useBreakpoint('md');

  return (
    <div className="grid grid-cols-1 items-start gap-2 md:grid-cols-2">
      {infoShown && (
        <div className="md:col-span-2">
          <InfoCard closeInfo={() => setInfoShown(false)} />
        </div>
      )}
      <div className="flex flex-col gap-2">
        <Contact className="max-w-full grow" />
        {!isMd && <MyServices className="max-w-full grow" />}

        <UnitsCard className="max-w-full grow" />
      </div>
      <div className="flex flex-col gap-2">
        {isMd && <MyServices className="max-w-full grow" />}
        <NewDocuments className="max-w-full grow" />
      </div>
    </div>
  );
}
