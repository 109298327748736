import { useCallback } from 'react';
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useContactData } from '@/lib/apiEndpoints';
import { useLocalStorageState } from '@/lib/hooks';
import { User } from '@/shared/types';
import { COMM_PREF_STORAGE_KEY } from '../constants';
import { Button, LinkButton } from './Layout';

const MONTH_MS = 24 * 30 * 3600_000;

function useCommunicationPrefStatus(userId: number) {
  const [lastAskedRecord, setLastAskedRecord] = useLocalStorageState<Record<string, number>>(
    COMM_PREF_STORAGE_KEY,
    {}
  );

  const setLastAsked = useCallback(
    (val: number) => {
      setLastAskedRecord({ ...lastAskedRecord, [userId]: val });
    },
    [userId, lastAskedRecord, setLastAskedRecord]
  );

  const lastAsked = lastAskedRecord[userId] ?? 0;

  const hide = useCallback(() => {
    setLastAsked(Date.now());
  }, [setLastAsked]);

  const shouldShow = lastAsked < Date.now() - MONTH_MS;

  return {
    shouldShow,
    hide
  } as const;
}

export function CommunicationPreferencePopup({ user }: { user: User }) {
  const { data: contact } = useContactData();

  const { shouldShow, hide } = useCommunicationPrefStatus(user.id);

  if (!contact || contact.communicationPreference === 'mail' || !shouldShow) {
    return null;
  }

  return (
    <div className="fixed inset-x-8 bottom-8 z-50 rounded-md border border-primary-600 bg-primary-50 p-4 pl-8 md:inset-x-[20%]">
      <div className="absolute -left-4 -top-4 rounded-full p-1 bg-red-50 border border-red-400">
        <ExclamationTriangleIcon className="h-8 w-8 text-red-600" />
      </div>

      <h3 className="text-lg font-bold text-primary-900 text-center">
        Momentan erhalten Sie Ihre Dokumente per Post.
      </h3>

      <p className="mt-2 text-center">
        Wollen Sie in Zukunft per <b>E-Mail</b> kontaktiert werden?
      </p>

      <div className="mt-8 flex flex-wrap justify-center gap-4 text-lg">
        <LinkButton to="/kontaktart" onClick={hide}>
          Korrespondenz per E-Mail beantragen
        </LinkButton>
        <Button onClick={hide}>Weiterhin per Post kontaktieren</Button>
      </div>

      <div className="absolute -top-2 -right-2 rounded-full border border-gray-600 bg-white">
        <button className="block p-0" title="Schließen">
          <XMarkIcon className="h-6 w-6" onClick={hide} />
        </button>
      </div>
    </div>
  );
}
