import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Button, FormRow, Input, Label, Select, Textarea } from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import { StatusError } from '@/lib/api';
import { postContactForm, useContactData, useLoggedInUser } from '@/lib/apiEndpoints';
import { errorToast } from '@/lib/toast';
import { ContactData, ContactFormData, User } from '@/shared/types';

const topics = ['Buchhaltung/Abrechnung', 'Vermietung', 'Allgemeines/Sonstiges'];
// topic

function ContactForm({ user, contact }: { user: User; contact: ContactData }) {
  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors }
  } = useForm<ContactFormData>({
    mode: 'all',
    defaultValues: {
      name: user.label,
      accountNumber: user.login,
      email: contact.email || '',
      topic: '',
      text: ''
    }
  });

  const mutation = useMutation(
    async (data: ContactFormData) => {
      return await postContactForm(data);
    },
    {
      onError: (error: StatusError) => {
        errorToast(error);
      }
    }
  );
  const isLoading = mutation.isLoading;

  const onSubmit = (data: ContactFormData) => {
    mutation.mutate(data);
  };

  const canSubmit = isValid && !isLoading && !mutation.isSuccess;

  return (
    <form>
      <div className="flex flex-col gap-2">
        <FormRow labelWidth="md:w-32">
          <Label htmlFor="name">Name</Label>
          <Input id="name" {...register('name')} />
        </FormRow>
        <div className="flex flex-col md:flex-row gap-2">
          <div className="flex-1">
            <FormRow labelWidth="md:w-32">
              <Label htmlFor="accountNumber">Kundennummer</Label>
              <Input
                id="accountNumber"
                {...register('accountNumber', { required: true })}
                hasError={errors['accountNumber'] != null}
              />
            </FormRow>
          </div>
          <div className="flex-1">
            <FormRow labelWidth="md:w-20">
              <Label htmlFor="email">E-Mail</Label>
              <Input
                id="email"
                {...register('email', { required: true })}
                hasError={errors['email'] != null}
              />
            </FormRow>
          </div>
        </div>

        <FormRow labelWidth="md:w-32">
          <Label htmlFor="name">Thema</Label>
          <Select
            id="topic"
            {...register('topic', { required: true })}
            hasError={errors['topic'] != null}
          >
            <option value="">Bitte wählen</option>
            {topics.map(topic => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </Select>
        </FormRow>

        <FormRow labelWidth="md:w-32">
          <Label htmlFor="text">Text</Label>
          <Textarea
            id="text"
            {...register('text', { required: true })}
            hasError={errors['text'] != null}
            rows={10}
          />
        </FormRow>

        {!mutation.isSuccess && (
          <Button
            className="self-start"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!canSubmit}
          >
            Anfrage absenden
          </Button>
        )}

        {!isValid && Object.values(touchedFields).some(x => Boolean(x)) && (
          <p className="text-red-600">Bitte füllen Sie alle Felder aus</p>
        )}

        {mutation.isSuccess && (
          <p className="text-green-600 text-lg mt-4">
            Vielen Dank für Ihre Anfrage, wir werden uns schnellstmöglich um Ihr Anliegen kümmern
            und Sie kontaktieren.
          </p>
        )}
      </div>
    </form>
  );
}

export default function ContactPage() {
  const { data: user } = useLoggedInUser();
  const { data: contact } = useContactData();
  return (
    <PageContainer>
      <PageHeader>Anfrageformular</PageHeader>

      <p className="mb-4">
        Haben Sie Fragen zu Ihrem Objekt, Ihren Unterlagen oder unseren Dienstleistungen? Füllen Sie
        einfach das folgende Formular aus.
      </p>

      {user && contact && <ContactForm user={user} contact={contact} />}
    </PageContainer>
  );
}
