// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

export function debounce<T extends (...args: never[]) => unknown>(
  fn: T,
  time: number
): (...args: Parameters<T>) => void {
  if (!time) {
    return fn;
  }
  let timeout = 0;

  return function (...args: Parameters<T>) {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(() => {
      return fn(...args);
    }, time);
  };
}

export function joinClasses(...args: Array<string | false | undefined | null>): string {
  return [...args].filter(Boolean).join(' ');
}

export function formatNumber(value: unknown) {
  if (value == null) {
    return '';
  }
  if (typeof value === 'number') {
    let valFixed = value.toFixed(2);
    if (valFixed === '-0.00') {
      valFixed = '0.00';
    }

    return `${valFixed.replace('.', ',')}`;
  }
  return String(value);
}

export function basename(filename: string) {
  const pIndex = filename.lastIndexOf('.');
  if (pIndex > 0) {
    return filename.slice(0, pIndex);
  }
  return filename;
}

export function groupBy<T, R>(collection: T[], fn: (x: T) => R): Map<R, T[]> {
  return collection.reduce((acc, elem) => {
    const key = fn(elem);
    let bucket = acc.get(key);
    if (!bucket) {
      bucket = [];
      acc.set(key, bucket);
    }

    bucket.push(elem);

    return acc;
  }, new Map<R, T[]>());
}

export function buildEmailValidationRegex() {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
}

export function createRange(start: number, end: number) {
  const vals: number[] = [];
  for (let i = start; i < end; i++) {
    vals.push(i);
  }
  return vals;
}
