import { PageContainer, PageHeader } from '@/components/PageContainer';
import ChangePassword from '@/components/user/ChangePassword';

export default function ChangePasswordPage({ redirectUrl }: { redirectUrl?: string }) {
  return (
    <PageContainer>
      <PageHeader>Passwort ändern</PageHeader>
      <ChangePassword redirectUrl={redirectUrl} />
    </PageContainer>
  );
}
