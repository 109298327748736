import React from 'react';
import { postApi } from '@/lib/api';
import { ErrorMessage } from './ErrorMessage';

export default class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state: { error?: Error; info?: unknown } = {};

  componentDidCatch(error: Error, info: unknown) {
    this.setState({ error, info });
    postApi('/api/error', { stack: error.stack, info, url: window.document.URL });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container p-8 text-center">
          <ErrorMessage error={this.state.error} info={this.state.info} />
        </div>
      );
    }
    return this.props.children;
  }
}
