import { useLayoutEffect, useMemo, useState } from 'react';
import { CollapsePanelList, DocsListByDate, DocumentsList } from '@/components/DocsUtils';
import { PageContainer } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { useObjectsAndMyFiles } from '@/lib/apiEndpoints';
import { groupBy } from '@/lib/utils';
import { FileI } from '@/shared/types';

function DocListObjects({ items }: { items: FileI[] }) {
  const buckets = useMemo(() => {
    const objectGroups = groupBy(items, x => x.object?.id ?? 0);

    const buckets = [...objectGroups.values()].map(items => {
      const first = items[0];
      return {
        label: first.object?.label ?? 'ohne Objekt',
        key: first.object?.number ?? 0,
        items
      };
    });

    return buckets
      .sort((a, b) => a.key - b.key)
      .map(({ key, ...rest }) => ({ key: `object-${key}`, ...rest }));
  }, [items]);

  return (
    <div className="ml-3">
      <CollapsePanelList buckets={buckets} initialOpen={false}>
        {items => <DocumentsList items={items} />}
      </CollapsePanelList>
    </div>
  );
}

export default function MyDocsPage() {
  const { data, error } = useObjectsAndMyFiles();

  const [groupByObject, setGroupByObject] = useState(false);

  const objectCount = useMemo(() => {
    const objetNos = new Set();
    data?.forEach(x => objetNos.add(x.object?.id ?? 0));
    return objetNos.size;
  }, [data]);

  useLayoutEffect(() => {
    if (objectCount > 1) {
      setGroupByObject(true);
    }
  }, [objectCount]);

  return (
    <PageContainer>
      <div className="flex gap-2 mb-4 justify-between">
        <h1 className="border-l-4 border-primary-600 pl-1 text-xl">Meine Dokumente</h1>
        {data && (
          <div className="flex gap-2 items-center">
            <label htmlFor="groupByObject" className="text-gray-600 text-sm">
              nach Objekt gruppieren
            </label>
            <input
              id="groupByObject"
              type="checkbox"
              checked={groupByObject}
              onChange={() => setGroupByObject(!groupByObject)}
            />
          </div>
        )}
      </div>
      <QueryWrapper data={data} error={error}>
        {data =>
          groupByObject ? (
            // <DocListObjects items={data} />
            <DocsListByDate data={data}>{items => <DocListObjects items={items} />}</DocsListByDate>
          ) : (
            <DocsListByDate data={data}>{items => <DocumentsList items={items} />}</DocsListByDate>
          )
        }
      </QueryWrapper>
    </PageContainer>
  );
}
