import { CSSProperties, RefObject, TransitionEvent, useLayoutEffect, useState } from 'react';
import { PropsWithChildren, useRef } from 'react';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon';
import { joinClasses } from '@/lib/utils';

const cState = {
  Enter: 'enter',
  Open: 'open',
  Exit: 'exit',
  Closed: 'closed'
} as const;
type TState = (typeof cState)[keyof typeof cState];

// css transitions don't work with height: auto. so we need to manually set the height in px during the transition phase
export default function useCollapsePanel<T extends HTMLElement>(
  containerRef: RefObject<T>,
  isOpen: boolean,
  scrollTo = false
) {
  const [state, setState] = useState<TState>(isOpen ? cState.Open : cState.Closed);
  const [style, setStyle] = useState<CSSProperties>({ height: 'auto' });

  const onTransitionEnd = (e: TransitionEvent<HTMLElement>) => {
    if (e.target !== containerRef.current) {
      // if a child element is animating, the parent receives transitionend too, skip this
      return;
    }
    if (state === cState.Enter) {
      if (scrollTo) {
        const { bottom } = containerRef.current!.getBoundingClientRect();
        if (bottom > window.innerHeight) {
          window.scrollTo({
            top: window.scrollY + bottom - window.innerHeight + 20,
            behavior: 'smooth'
          });
        }
      }
      setState(cState.Open);
    } else if (state === cState.Exit) {
      setState(cState.Closed);
    } else {
      console.log('unknown transition', state);
      setState(cState.Closed);
    }
  };

  useLayoutEffect(() => {
    if (isOpen) {
      if (state === cState.Closed) {
        setState(cState.Enter);
      } else if (state === cState.Enter) {
        const container = containerRef.current!;
        const height = container.offsetHeight;
        setStyle({ height: 0 });
        requestAnimationFrame(() => {
          setStyle({ height: height });
        });
      } else if (state === cState.Open) {
        setStyle({ height: 'auto' });
      } else if (state === cState.Exit) {
        setStyle({ height: 'auto' });
        setState(cState.Closed);
      }
    } else {
      if (state === cState.Open) {
        setState(cState.Exit);
      } else if (state === cState.Enter) {
        setState(cState.Open);
      } else if (state === cState.Exit) {
        const container = containerRef.current!;
        setStyle({ height: container.offsetHeight });
        requestAnimationFrame(() => {
          setStyle({ height: 0 });
        });
      } else if (state === cState.Closed) {
        setStyle({ height: 'auto' });
      }
    }
  }, [isOpen, state, containerRef]);

  const isTransitioning = state === cState.Enter || state === cState.Exit;

  const className = joinClasses(
    state === cState.Closed && 'hidden',
    isTransitioning && 'overflow-hidden transition-[height] duration-200 ease-out'
  );

  return { onTransitionEnd, style, className };
}

export function CollapsePanel({
  isOpen,
  children,
  className,
  scrollTo = false
}: PropsWithChildren<{ isOpen: boolean; scrollTo?: boolean; className?: string }>) {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    onTransitionEnd,
    style,
    className: cClassName
  } = useCollapsePanel(containerRef, isOpen, scrollTo);

  return (
    <div
      className={joinClasses(cClassName, className)}
      style={style}
      onTransitionEnd={onTransitionEnd}
      ref={containerRef}
    >
      {children}
    </div>
  );
}

export function CollapsePanelContent({
  isOpen,
  belowOpen,
  children
}: PropsWithChildren<{ isOpen: boolean; belowOpen: boolean }>) {
  return (
    <div
      className={joinClasses(
        isOpen
          ? 'border-y-2 first:border-t-0 last:border-b-0 border-primary-400 -my-px'
          : belowOpen
            ? undefined
            : 'border-b border-dashed border-gray-300 last:border-b-0'
      )}
    >
      {children}
    </div>
  );
}

export function CollapsePanelButton({
  id,
  isOpen,
  onClick,
  children
}: PropsWithChildren<{ id: string; isOpen: boolean; onClick: () => void }>) {
  return (
    <button
      id={id}
      type="button"
      className={joinClasses(
        'py-2 flex items-center text-left font-bold text-gray-600 focus-visible:text-primary-600 w-full',
        isOpen ? 'text-primary-900' : 'text-gray-600'
      )}
      onClick={onClick}
    >
      {isOpen ? (
        <ChevronUpIcon
          key="chev_up"
          className="mr-1 h-5 w-5 shrink-0 -translate-x-1 text-primary-400 transition-transform md:mr-2"
        />
      ) : (
        <ChevronDownIcon
          key="chev_down"
          className="mr-1 h-5 w-5 shrink-0 -translate-x-1 text-primary-400 transition-transform md:mr-2"
        />
      )}
      <span>{children}</span>
    </button>
  );
}
