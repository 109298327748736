import { forwardRef, useEffect } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Button, FormRow, Input, Label, LinkButton } from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import Spinner from '@/components/Spinner';
import { StatusError } from '@/lib/api';
import { postContactUpdate, useContactData } from '@/lib/apiEndpoints';
import { toast } from '@/lib/toast';
import { ContactData, UpdateableContactData } from '@/shared/types';

const stringInputOptions = { setValueAs: (x: string) => x?.trim() || null };

function getDefaultData(data: ContactData): UpdateableContactData {
  return {
    phone: data.phone,
    phone2: data.phone2,
    mobile: data.mobile,
    email: data.email,
    fax: data.fax,
    ss_mailing: data.ss_mailing
  };
}

function getChanges<T extends object>(newData: Partial<T>, oldData: T): Partial<T> {
  const changes: Partial<T> = {};

  (Object.keys(newData) as (keyof T)[]).forEach(key => {
    if (newData[key] !== oldData[key]) {
      changes[key] = newData[key];
    }
  });

  return changes;
}

const ContactRow = forwardRef(_ContactRow);
function _ContactRow(
  {
    id,
    placeholder,
    error,
    ...props
  }: React.InputHTMLAttributes<HTMLInputElement> & { error?: FieldError },
  ref: React.Ref<HTMLInputElement>
) {
  const errorMessage = error && (error.type === 'required' ? 'Pflichtfeld' : error.message);
  const shownPlaceholder = [placeholder, errorMessage].filter(Boolean).join(' - ');

  return (
    <FormRow labelWidth="md:w-20">
      <Label htmlFor={id}>{placeholder}</Label>
      <Input id={id} placeholder={shownPlaceholder} ref={ref} hasError={!!error} {...props} />
    </FormRow>
  );
}

function isFilledString(x: string | null | undefined): boolean {
  return x != null && x.trim().length > 0;
}

function ChangeContact({ data }: { data: ContactData }) {
  const hasPendingUpdates = data.pendingUpdate != null;

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isDirty, errors, defaultValues },
    trigger,
    watch
  } = useForm<UpdateableContactData>({
    mode: 'onChange',
    defaultValues: getDefaultData(data),
    resolver: data => {
      const phonesValid =
        isFilledString(data.phone) || isFilledString(data.phone2) || isFilledString(data.mobile);

      if (phonesValid) {
        return {
          values: data,
          errors: {}
        };
      }

      return {
        values: {},
        errors: {
          phone: { type: 'validate', message: 'Telefon oder Mobilnummer muss angegeben werden' }
        }
      };
    }
  });

  // need to trigger revalidation when one of the phone values changes
  const [phone, phone2, mobile] = watch(['phone', 'phone2', 'mobile']);
  useEffect(() => {
    trigger();
  }, [trigger, phone, phone2, mobile]);

  const mutation = useMutation(
    async (data: UpdateableContactData) => {
      const changes = getChanges(data, defaultValues!);
      const res = await postContactUpdate(changes);
      return res;
    },
    {
      onSuccess: res => {
        reset(getDefaultData(res));
        toast('Ihre Kontaktdaten wurden erfolgreich geändert', 'success');
      },
      onError(err: StatusError) {
        toast(`Fehler beim Ändern der Kontaktdaten ${err.message}`, 'error');
      }
    }
  );

  const isLoading = mutation.isLoading;

  const onSubmit = (data: UpdateableContactData) => {
    if (isLoading) {
      return;
    }
    mutation.mutate(data);
  };

  return (
    <div>
      <p className="mb-2">
        Mit dem Absenden des folgenden Formulars werden Ihre Änderungswünsche an uns übermittelt und
        nach einer Prüfung freigeschaltet.
      </p>
      {hasPendingUpdates && (
        <p className="mb-2 text-red-600">
          Sie haben bereits einen Änderungswunsch hinterlegt, dieser wurde von uns aber noch nicht
          bearbeitet.
        </p>
      )}

      <form
        className="mt-4 grid grid-cols-1 gap-2 md:mt-8 md:grid-cols-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/*         <div className="col-span-2">
          <input type="checkbox" {...register('ss_mailing')} />
          <Label htmlFor="ss_mailing" className="ml-2">
            Bei neuen Dokumenten eine E-Mail-Benachrichtigung erhalten
          </Label>
        </div> */}
        <ContactRow
          id="phone"
          placeholder="Telefon"
          error={errors['phone']}
          {...register('phone', stringInputOptions)}
        />
        <ContactRow
          id="phone2"
          placeholder="Telefon 2"
          {...register('phone2', stringInputOptions)}
        />
        <ContactRow
          id="mobile"
          placeholder="Mobil"
          {...register('mobile', { ...stringInputOptions })}
        />
        <ContactRow id="fax" placeholder="Fax" {...register('fax', stringInputOptions)} />
        <ContactRow id="email" placeholder="E-Mail" {...register('email', stringInputOptions)} />

        <div className="mt-8 md:col-span-2 md:mx-auto md:w-80">
          <Button
            type="submit"
            value="Login"
            className="w-full text-base"
            variant={isValid ? 'primary' : 'red'}
            disabled={isLoading || !isValid || !isDirty}
          >
            {isLoading ? <Spinner className="inline-block" /> : 'Änderungen absenden'}
          </Button>
        </div>
      </form>
      {data.communicationPreference === 'letter' && (
        <div className="mt-16 rounded-md border border-primary-600 bg-primary-50 p-4">
          <h3 className="text-lg font-bold text-primary-900 text-center">
            Momentan erhalten Sie Ihre Dokumente per Post.
          </h3>

          <p className="mt-2 text-center">
            Wollen Sie in Zukunft per <b>E-Mail</b> kontaktiert werden?
          </p>

          <div className="mt-8 flex flex-wrap justify-center gap-4 text-lg">
            <LinkButton to="/kontaktart">Korrespondenz per E-Mail beantragen</LinkButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default function ChangeContactPage() {
  const { data, error } = useContactData();

  return (
    <PageContainer>
      <PageHeader>Antrag zur Änderung der Kontaktdaten</PageHeader>
      <QueryWrapper data={data} error={error}>
        {data => <ChangeContact data={data} />}
      </QueryWrapper>
    </PageContainer>
  );
}
