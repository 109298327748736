import { useMemo, useState } from 'react';
import {
  CollapsePanel,
  CollapsePanelButton,
  CollapsePanelContent
} from '@/components/CollapsePanel';
import { DocumentsList } from '@/components/DocsUtils';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { useObjectFiles } from '@/lib/apiEndpoints';
import { groupBy } from '@/lib/utils';
import { FileI } from '@/shared/types';

function ObjectsDocsList({ data }: { data: FileI[] }) {
  const buckets = useMemo(() => {
    const grouped = groupBy(data, x => x.object?.number);

    const buckets = [...grouped.values()].map(items => {
      return {
        objectNumber: items[0].object?.number,
        objectLabel: items[0].object?.label,
        items
      };
    });
    return buckets;
  }, [data]);
  const [openKey, setOpenKey] = useState<number | null>(
    buckets.length === 1 ? buckets[0].objectNumber! : null
  );
  const toggleKey = (key: number) => {
    setOpenKey(key === openKey ? null : key);
  };

  return (
    <div className="flex flex-col flex-wrap">
      {buckets.map((bucket, idx) => {
        const isOpen = bucket.objectNumber === openKey;
        const belowOpen = buckets[idx + 1] ? buckets[idx + 1].objectNumber === openKey : false;
        return (
          <CollapsePanelContent key={bucket.objectNumber} isOpen={isOpen} belowOpen={belowOpen}>
            <CollapsePanelButton
              isOpen={isOpen}
              id={String(bucket.objectNumber)}
              onClick={() => toggleKey(bucket.objectNumber!)}
            >
              <span>
                {bucket.objectLabel}{' '}
                <span className="text-sm text-gray-400">
                  {String(bucket.objectNumber).padStart(4, '0')}
                </span>
              </span>
            </CollapsePanelButton>

            <CollapsePanel isOpen={isOpen}>
              <DocumentsList items={bucket.items} />
            </CollapsePanel>
          </CollapsePanelContent>
        );
      })}
    </div>
  );
}

export default function ObjectDocsPage() {
  const { data, error } = useObjectFiles();

  return (
    <PageContainer>
      <PageHeader>WEG Dokumente</PageHeader>
      <QueryWrapper data={data} error={error}>
        {data => <ObjectsDocsList data={data} />}
      </QueryWrapper>
    </PageContainer>
  );
}
