import { joinClasses } from '@/lib/utils';
import Spinner from './Spinner';

export default function LoadingPlaceholder({
  className,
  loadingText
}: {
  className?: string;
  loadingText?: React.ReactNode;
}) {
  return (
    <div
      className={joinClasses('flex gap-2 w-full flex-1 items-center justify-center p-8', className)}
    >
      <Spinner className="h-16 w-16" />
      {loadingText && <span className="text-lg text-gray-600">{loadingText}</span>}
    </div>
  );
}
