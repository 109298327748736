import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from '@/components/Spinner';
import { StatusError, postApi } from '@/lib/api';
import { Button, FloatingInput } from '../Layout';

type LoginData = { login: string; password: string };

const defaultLogin = process.env.NODE_ENV === 'development' ? '2097142' : '';

function LoginError() {
  return (
    <div>
      <p className="text-red-600 text-lg">Anmeldung fehlgeschlagen</p>
      <p className="mt-2">
        Das Kundenkonto existiert nicht oder Sie haben Ihr Passwort ist falsch eingegeben.
      </p>
      <p className="mt-2">Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.</p>
      <p className="mt-2">
        Wenn Sie sich nicht einloggen können, rufen Sie uns an unter{' '}
        <b className="whitespace-nowrap">0941 30717-0</b> an oder schreiben Sie uns eine E-Mail an{' '}
        <a href="mailto:service@riebeling.eu" className="text-primary-600">
          service@riebeling.eu
        </a>
        .
      </p>
    </div>
  );
}

export default function Login({ redirectUrl }: { redirectUrl?: string }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<LoginData>({
    mode: 'onChange',
    defaultValues: { login: defaultLogin, password: '' }
  });

  const mutation = useMutation(
    async (data: LoginData) => {
      return await postApi('/api/login', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        if (redirectUrl) {
          requestAnimationFrame(() => {
            navigate(redirectUrl, { replace: true });
          });
        }
      }
    }
  );

  const mutationError = mutation.error as undefined | StatusError;
  const isLoading = mutation.isLoading;

  const onSubmit = (data: LoginData) => {
    if (isLoading) {
      return;
    }
    mutation.mutate(data);
  };

  return (
    <form className="flex w-full max-w-sm flex-col gap-4 text-lg" onSubmit={handleSubmit(onSubmit)}>
      <FloatingInput
        id="login"
        autoFocus={true}
        type="text"
        autoComplete="user"
        placeholder="Kundennummer"
        {...register('login', { required: true })}
      />
      <FloatingInput
        id="password"
        type="password"
        autoComplete="current-password"
        placeholder="Passwort"
        {...register('password', { required: true })}
      />
      <div>
        <div>
          <Button
            type="submit"
            value="Login"
            className="w-full"
            variant="inverted"
            disabled={isLoading || !isValid}
          >
            {isLoading ? <Spinner className="inline-block" /> : 'Login'}
          </Button>
          {!!mutationError && (
            <div className="my-4 mx-2">
              {mutationError.statusCode === 401 ? (
                <LoginError />
              ) : (
                <div className="text-red-600">{mutationError.message}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
