import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useLoggedInUser } from '@/lib/apiEndpoints';
import { useBreakpoint, useWindowListener } from '@/lib/hooks';
import { joinClasses } from '@/lib/utils';
import { IconType } from '@/types/type-utils';
import Logo from '../Logo';
import { UserSettings } from '../UserSettings';

export function HeaderIcon({ Icon }: { Icon: IconType }) {
  return <Icon className="inline-block h-6 w-6 shrink-0 text-gray-600" />;
}

const MENU_BUTTON_ID = 'menu-button';

export function HeaderLink({
  to,
  end = false,
  Icon,
  children
}: PropsWithChildren<{
  to: string;
  end?: boolean;
  Icon: IconType;
}>) {
  return (
    <NavLink
      end={end}
      to={to}
      data-to={to}
      className={({ isActive }) =>
        joinClasses(
          'flex items-center py-3 pl-3 pr-2 hover:bg-primary-600/20',
          isActive && 'bg-primary-600/20'
        )
      }
    >
      <HeaderIcon Icon={Icon} />
      <span className="ml-4">{children}</span>
    </NavLink>
  );
}

export function Menu({
  children,
  isOpen,
  setIsOpen
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const location = useLocation();
  const linkContainer = useRef<HTMLDivElement>(null);
  const [linkMarkerStyle, setLinkMarkerStyle] = useState<CSSProperties>({});

  const calcLinkMarkerStyle = () => {
    const linkElems = [...linkContainer.current!.querySelectorAll<HTMLAnchorElement>('a[data-to]')];
    const matchingElement = linkElems.find(elem => {
      const href = elem.getAttribute('data-to')!;

      const isActive =
        location.pathname === href ||
        (location.pathname.startsWith(href) && location.pathname.charAt(href.length) === '/');

      return isActive;
    });

    if (matchingElement) {
      setLinkMarkerStyle({ top: matchingElement.offsetTop, height: matchingElement.offsetHeight });
    }
  };

  useEffect(calcLinkMarkerStyle, [location]);
  useWindowListener('resize', calcLinkMarkerStyle);

  useEffect(() => {
    if (isOpen) {
      const onClick = (e: MouseEvent): void => {
        const target = e.target as HTMLElement;
        const menuButton = document.getElementById(MENU_BUTTON_ID);

        if (!menuButton?.contains(target) && !linkContainer.current!.contains(target)) {
          setIsOpen(false);
        }
      };
      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [isOpen, setIsOpen]);

  return (
    <div
      className={joinClasses(
        'fixed lg:sticky top-16 lg:top-20 z-20 h-[calc(100vh-3rem)] lg:h-[calc(100vh-5rem)]',
        isOpen ? 'w-full lg:w-auto max-lg:backdrop-blur-sm' : 'w-0'
      )}
      aria-hidden={!isOpen}
    >
      <div
        className={joinClasses(
          'overflow-x-hidden h-full transition-all',
          isOpen ? 'w-[calc(100vw-4rem)] md:w-80 lg:w-auto' : 'w-0'
        )}
      >
        <div
          ref={linkContainer}
          className={joinClasses(
            'relative flex min-h-[calc(100vh-7rem)] lg:min-h-full flex-col items-stretch w-[calc(100vw-4rem)] md:w-80 lg:w-auto',
            'border-r border-b border-gray-300 bg-gray-100 max-lg:shadow-xl'
          )}
        >
          {children}
          <div
            role="presentation"
            className="absolute left-0 w-1 bg-primary-600 transition-all duration-200 ease-out"
            style={linkMarkerStyle}
          ></div>
        </div>
      </div>
    </div>
  );
}

function MenuButton({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  return (
    <div className="absolute inset-y-0 left-0 h-full lg:hidden">
      <button
        id={MENU_BUTTON_ID}
        className="relative flex h-full w-12 items-center p-3"
        title={isOpen ? 'Menu schließen' : 'Menu "öffnen"'}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bars3Icon
          className={joinClasses(
            'h-6 w-6 text-gray-600 transition-opacity absolute',
            isOpen && 'opacity-0'
          )}
        />
        <XMarkIcon
          className={joinClasses(
            'h-6 w-6 text-gray-600 transition-opacity absolute',
            !isOpen && 'opacity-0'
          )}
        />
      </button>
    </div>
  );
}

function useMenuState() {
  const isLg = useBreakpoint('lg');
  const [_isOpen, setIsOpen] = useState(isLg);
  const isOpen = isLg || _isOpen;

  const location = useLocation();
  useEffect(() => {
    if (!isLg) {
      setIsOpen(false);
    }
  }, [location, isLg]);

  useEffect(() => {
    document.documentElement.style.overflow = !isLg && isOpen ? 'hidden' : '';
  }, [isOpen, isLg]);

  return [isOpen, setIsOpen] as const;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { data: user } = useLoggedInUser();
  const [isOpen, setIsOpen] = useMenuState();

  return (
    <div className="flex min-h-screen flex-col">
      <div className="sticky z-10 top-0 h-16 lg:h-20 gap-2 border-b-2 border-primary-600 bg-primary-50/50 backdrop-blur-lg">
        <MenuButton isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="h-full p-2 text-center flex items-center justify-center">
          <Link to="/" className={joinClasses(user && 'mr-28', 'lg:mr-0')}>
            <Logo className="inline h-10 lg:h-14" />
          </Link>
        </div>
        {user && (
          <div className="absolute right-0 top-0 flex h-full w-36 items-center justify-end pr-2 text-right text-sm md:w-72 lg:w-96">
            <UserSettings user={user} />
          </div>
        )}
      </div>
      <div className="flex flex-1">
        <Menu isOpen={isOpen} setIsOpen={setIsOpen}>
          {children}
        </Menu>
        <div className="flex-1 p-2 lg:px-4 w-0">
          {/*  somehow this needs a width, otherwise the layout is broken */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
