import { PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { usePage } from '@/lib/apiEndpoints';

export default function MdPage({ page }: { page: string }) {
  const { data, error } = usePage(page);

  return (
    <div className="mx-auto max-w-2xl p-2">
      <QueryWrapper data={data} error={error}>
        {data => (
          <>
            {data.title && <PageHeader>{data.title}</PageHeader>}
            <div className="prose" dangerouslySetInnerHTML={{ __html: data.content }} />
          </>
        )}
      </QueryWrapper>
    </div>
  );
}
