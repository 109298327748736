import { FileI } from '@/shared/types';

function trimExtension(filename: string) {
  const pIndex = filename.lastIndexOf('.');
  if (pIndex === -1) {
    return filename;
  }

  return filename.slice(0, pIndex);
}

function boundReplaceRegex(valueString: string) {
  return new RegExp(`(-|_|\\s|^)${valueString}(-|_|\\s|$)`, 'g');
}

export function groupFilesByDate<T extends { date?: string }>(files: T[]) {
  const buckets = new Map<string, T[]>();
  files.forEach(elem => {
    const key = elem.date ? new Date(elem.date).getFullYear().toString() : 'ohne Datum';
    const bucket = buckets.get(key);
    if (bucket) {
      bucket.push(elem);
    } else {
      buckets.set(key, [elem]);
    }
  });

  return [...buckets.entries()].map(([key, bucket]) => {
    return {
      key,
      items: bucket
    };
  });
}

const DATE_RE = /\d{4}-\d{2}-\d{2}/;

type FormatDocReturn = {
  title: string;
  subtitle: string;
};

export function formatDoc(
  doc: FileI,
  { showObjects = true }: { showObjects?: boolean } = {}
): FormatDocReturn {
  let filename = trimExtension(doc.filename);

  if (doc.date) {
    // replace full date string and year only
    filename = filename.replace(DATE_RE, '').replace(boundReplaceRegex(doc.date.slice(0, 4)), ' ');
  }

  const postfixes = [];

  if (doc.object?.number) {
    filename = filename.replace(boundReplaceRegex(String(doc.object.number)), '');
    if (showObjects) {
      postfixes.push(doc.object.label);
    }
  }

  if (doc.unit?.contractNo) {
    filename = filename.replace(boundReplaceRegex(String(doc.unit.contractNo)), '');
    if (showObjects) {
      postfixes.push(doc.unit.label);
    }
  }

  filename = filename.replace(/[_ ]+/g, ' ').trim(); // trim duplicate spaces, dashes

  return { title: filename, subtitle: postfixes.join(' - ') };
}
