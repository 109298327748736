export const COMM_PREF_STORAGE_KEY = 'selfservice-last-used';

export const JPW_OBJECTS = [
  5060, 5090, 5091, 5095, 5096, 5097, 5098, 5099, 5100, 5150, 5160, 5161, 5162, 8010, 8015, 8020,
  8025, 8300, 8310
];

export const SEV_OBJECTS = [
  50,
  60,
  70,
  71,
  72,
  73,
  74,
  75,
  77,
  80,
  90,
  65,
  66,
  85,
  86,
  ...JPW_OBJECTS
];
