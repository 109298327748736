import React, { PropsWithChildren, Suspense, useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import BuildingOffice2Icon from '@heroicons/react/24/outline/BuildingOffice2Icon';
import EnvelopeIcon from '@heroicons/react/24/outline/EnvelopeIcon';
import FolderIcon from '@heroicons/react/24/outline/FolderIcon';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import KeyIcon from '@heroicons/react/24/outline/KeyIcon';
import PencilSquareIcon from '@heroicons/react/24/outline/PencilSquareIcon';
import PowerIcon from '@heroicons/react/24/outline/PowerIcon';
import QuestionMarkCircleIcon from '@heroicons/react/24/outline/QuestionMarkCircleIcon';
import SquaresIcon from '@heroicons/react/24/outline/Squares2X2Icon';
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon';
import { QueryClientProvider } from '@tanstack/react-query';
// import BuildingOfficeIcon from '@heroicons/react/24/outline/BuildingOfficeIcon';
import AuthenticatedWrapper from '@/components/AuthenticatedWrapper';
import { CommunicationPreferencePopup } from '@/components/CommunicationPreferencePopup';
import ErrorBoundary from '@/components/ErrorBoundary';
import { Button } from '@/components/Layout';
import { HeaderIcon, HeaderLink, Layout } from '@/components/Layout/MainLayout';
import Modal from '@/components/Modal';
import { useLoggedInUser } from '@/lib/apiEndpoints';
import { getQueryClient } from '@/lib/queryClient';
import { ToastContainer } from '@/lib/toast';
import { useLogout } from '@/lib/userUtils';
import ChangeContactPage from '@/pages/ChangeContactPage';
import ChangePasswordPage from '@/pages/ChangePasswordPage';
import DashboardPage from '@/pages/DashboardPage';
import FaqPage from '@/pages/FaqPage';
import LoginPage from '@/pages/LoginPage';
import MdPage from '@/pages/MdPage';
import UnitPage from '@/pages/UnitDetailPage';
import UnitsPage from '@/pages/UnitsPage';
import GlobalDocsPage from '@/pages/storage/GlobalDocsPage';
// import MyDocsPage from '@/pages/storage/MyDocsPage';
import MyDocsPage from '@/pages/storage/MyDocsPageUnits';
// import EtKorrespDocsPage from '@/pages/storage/EtKorrespDocsPage';
// import AllDocsPage from '@/pages/storage/AllDocsPage';
// import UnitDocsPage from '@/pages/storage/UnitDocsPage';
import ObjectsDocsPage from '@/pages/storage/ObjectsDocsPage';
import { IconType } from '@/types/type-utils';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import { initErrorLogger } from './lib/errorLogger';
import useNavigation from './lib/useNavigation';
import ConfirmCommunicationPreferencePage from './pages/ConfirmCommunicationPreferencePage';
import ContactFormPage from './pages/ContactFormPage';
import './styles/main.css';

// const GraphPageLazy = React.lazy(() => import('@/pages/GraphPage'));
const CommunicationPreferencePage = React.lazy(() => import('@/pages/CommunicationPreferencePage'));

const BASENAME = process.env.APP_BASENAME || '';

initErrorLogger();

function LogoutButton() {
  const logout = useLogout();
  const [modalShown, setModalShown] = useState(false);

  const onClick = () => {
    setModalShown(true);
  };

  return (
    <>
      <button
        onClick={onClick}
        className="flex items-center py-2 pl-3 pr-2 hover:bg-red-600/20 lg:py-3"
      >
        <HeaderIcon Icon={PowerIcon} />
        <span className="ml-4">Logout</span>
      </button>
      <Modal isOpen={modalShown} blur closeModal={() => setModalShown(false)}>
        <div className="flex flex-col items-center text-gray-800">
          <div className="rounded-full border border-red-400 bg-red-100 p-2">
            <PowerIcon className="h-12 w-12 text-red-400" />
          </div>
          <h3 className="m-4 text-center text-lg">Wollen Sie sich wirklich abmelden?</h3>
          <div className="flex gap-4">
            <Button className="w-24 font-bold" variant="green" onClick={logout}>
              Ja
            </Button>
            <Button className="w-24 font-bold" onClick={() => setModalShown(false)}>
              Nein
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function PageWrapper({
  authenticated = false,
  children
}: PropsWithChildren<{ authenticated?: boolean }>) {
  if (authenticated) {
    return <AuthenticatedWrapper>{children}</AuthenticatedWrapper>;
  }
  return <>{children}</>;
}

type MenuRoute = {
  path: string;
  title: string;
  icon: IconType;
  element: JSX.Element;
};

const routes: MenuRoute[] = [
  {
    path: '',
    title: 'Dashboard',
    icon: SquaresIcon,
    element: (
      <PageWrapper authenticated>
        <DashboardPage />
      </PageWrapper>
    )
  },
  {
    path: 'kontakt',
    title: 'Kontaktdaten',
    icon: PencilSquareIcon,
    element: (
      <PageWrapper authenticated>
        <ChangeContactPage />
      </PageWrapper>
    )
  },
  {
    path: 'faq',
    title: 'FAQ',
    icon: QuestionMarkCircleIcon,
    element: (
      <PageWrapper authenticated>
        <FaqPage />
      </PageWrapper>
    )
  },
  {
    path: 'einheiten',
    title: 'Meine Einheiten',
    icon: BuildingOffice2Icon,
    element: (
      <PageWrapper authenticated>
        <UnitsPage />
      </PageWrapper>
    )
  },
  {
    path: 'dokumente-allgemein',
    title: 'Allgemeine Informationen',
    icon: InformationCircleIcon,
    element: (
      <PageWrapper authenticated>
        <GlobalDocsPage />
      </PageWrapper>
    )
  },
  {
    path: 'dokumente-objekte',
    title: 'WEG Dokumente',
    icon: FolderIcon,
    element: (
      <PageWrapper authenticated>
        <ObjectsDocsPage />
      </PageWrapper>
    )
  },
  {
    path: 'dokumente',
    title: 'Meine Dokumente',
    icon: FolderIcon,
    element: (
      <PageWrapper authenticated>
        <MyDocsPage />
      </PageWrapper>
    )
  },
  {
    path: 'kontaktformular',
    title: 'Kontaktformular',
    icon: EnvelopeIcon,
    element: (
      <PageWrapper authenticated>
        <ContactFormPage />
      </PageWrapper>
    )
  },
  {
    path: 'passwort',
    title: 'Passwort ändern',
    icon: KeyIcon,
    element: (
      <PageWrapper authenticated>
        <ChangePasswordPage />
      </PageWrapper>
    )
  }
];

function LayoutWithHeader() {
  const { data: user } = useLoggedInUser();
  const location = useLocation();

  const shouldShowPopup =
    user && location.pathname !== '/kontakt' && location.pathname !== '/kontaktart';

  return (
    <>
      <Layout>
        {user ? (
          <>
            {routes.map(x => {
              return (
                <HeaderLink to={`/${x.path}`} key={`/${x.path}`} Icon={x.icon}>
                  {x.title}
                </HeaderLink>
              );
            })}
            <div className="flex flex-1 flex-col justify-end">
              <LogoutButton />
            </div>
          </>
        ) : (
          <HeaderLink to="/login" Icon={UserCircleIcon}>
            Login
          </HeaderLink>
        )}
      </Layout>
      {shouldShowPopup && <CommunicationPreferencePopup user={user} />}
    </>
  );
}

function CatchAll() {
  return (
    <div className="text-center">
      <h1 className="mt-4 m-auto text-center text-lg">Diese Seite existiert nicht.</h1>
      <div className="text-center">
        <Link to="/" className="underline text-primary-900">
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
}

const mdPages = [
  'impressum',
  'informationspflichten',
  'datenschutz',
  'sev',
  'sev-faq',
  'pflichtwartung',
  'pflichtwartung-faq'
];

function Main() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useNavigation();

  return (
    <div className="bg-white text-gray-800">
      <Routes>
        <Route path="login" element={<LoginPage redirectUrl="/" />} />
        <Route element={<LayoutWithHeader />}>
          {routes.map(route => (
            <Route
              key={route.path}
              index={route.path === ''}
              path={route.path === '' ? undefined : route.path}
              element={route.element}
            />
          ))}
          <Route
            path="einheiten/:unitKey"
            element={
              <PageWrapper authenticated>
                <UnitPage />
              </PageWrapper>
            }
          />
          <Route
            path="kontaktart"
            element={
              <PageWrapper authenticated>
                <Suspense fallback={<LoadingPlaceholder />}>
                  <CommunicationPreferencePage />
                </Suspense>
              </PageWrapper>
            }
          />
          <Route
            path="kontaktbestaetigung/:token"
            element={
              <PageWrapper authenticated>
                <ConfirmCommunicationPreferencePage />
              </PageWrapper>
            }
          />
          {/* <Route
            path="graph/:unitId"
            element={
              <PageWrapper authenticated>
                <Suspense fallback={<LoadingPlaceholder />}>
                  <GraphPageLazy />
                </Suspense>
              </PageWrapper>
            }
          /> */}
          {mdPages.map(page => (
            <Route key={page} path={page} element={<MdPage page={page} />} />
          ))}
        </Route>
        <Route path="*" element={<CatchAll />} />
      </Routes>
    </div>
  );
}

const queryClient = getQueryClient();
export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={BASENAME}>
          <Main />
          <ToastContainer />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

createRoot(document.getElementById('root')!).render(<App />);
