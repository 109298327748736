import { useMutation } from '@tanstack/react-query';
import { postApi } from '@/lib/api';
import { postBookingSheet } from '@/lib/apiEndpoints';
import downloadBlob from '@/lib/downloadBlob';
import { errorToast } from '@/lib/toast';
import { UnitData } from '@/shared/types';

export function useDownloadSheet({
  unit,
  year,
  type
}: {
  unit: UnitData;
  year: number;
  type: 'pdf' | 'xlsx';
}) {
  const mutation = useMutation(
    async () => {
      const data = await postBookingSheet({
        unitKey: `${unit.objectNumber}-${unit.unitNumber}`,
        year
      });
      return await postApi(
        `/api/bookings/${type === 'pdf' ? 'pdf_export' : 'excel_export'}`,
        {
          data,
          year
        },
        undefined,
        'blob'
      );
    },
    {
      onSuccess: buffer => {
        downloadBlob(
          buffer,
          `buchungsblatt_W${unit.objectNumber}_E${unit.unitNumber}_${year}.${type}`
        );
      },
      onError: err => {
        errorToast(err as Error);
      }
    }
  );

  return mutation;
}
