export default function downloadBlob(blob: Blob, filename: string) {
  const dataUri = URL.createObjectURL(blob);
  const ahref = document.createElement('a');
  ahref.href = dataUri;
  ahref.download = filename;
  ahref.style.display = 'none';
  document.body.appendChild(ahref);
  ahref.click();
  document.body.removeChild(ahref);
  URL.revokeObjectURL(dataUri);
}
