import React, { useState } from 'react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useConstructor } from './hooks';
import { joinClasses } from './utils';

type ToastType = 'error' | 'info' | 'warn' | 'success';
interface Toast {
  id: number;
  time: number;
  elem: React.ReactNode;
  type: ToastType;
}

let setToasts: React.Dispatch<React.SetStateAction<Toast[]>> | undefined;
const timeoutMap = new Map<number, number>();
let idCounter = 0;

const toastClasses = {
  error: 'bg-red-50 border-red-400 text-red-800',
  info: 'bg-blue-50 border-blue-400 text-blue-800',
  warn: 'bg-yellow-50 border-yellow-400 text-yellow-800',
  success: 'bg-green-50 border-green-400 text-green-800'
};

function ToastPanel({ toast }: { toast: Toast }) {
  const onEnter = () => {
    if (timeoutMap.has(toast.id)) {
      window.clearTimeout(timeoutMap.get(toast.id));
    }
  };
  const removeToast = () => setToasts?.(toasts => toasts.filter(x => x !== toast));

  return (
    <div
      className={joinClasses(
        'relative rounded border p-4 shadow-md text-xl',
        toastClasses[toast.type]
      )}
      onMouseDown={onEnter}
    >
      <div>{toast.elem}</div>
      <button
        className="absolute -top-3 -right-3 rounded-full border border-gray-400 bg-white p-1 text-gray-600"
        aria-label="close"
        type="button"
        onClick={removeToast}
      >
        <XMarkIcon className="h-5 w-5 stroke-2" />
      </button>
    </div>
  );
}

export function ToastContainer() {
  const [toasts, _setToasts] = useState<Toast[]>([]);

  useConstructor(() => {
    setToasts = _setToasts;
    () => {
      setToasts = undefined;
    };
  });

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div className="fixed top-8 left-1/2 z-50 h-0">
      <div className="flex -translate-x-1/2 flex-col items-center space-y-2">
        {toasts.map(toast => (
          <ToastPanel key={toast.id} toast={toast} />
        ))}
      </div>
    </div>
  );
}

export function toast(elem: React.ReactNode, type: ToastType = 'info', timeout = 5000) {
  const currentId = idCounter++;
  setToasts?.(toasts => {
    return [
      {
        elem,
        id: currentId,
        time: Date.now(),
        type
      },
      ...toasts
    ];
  });

  if (timeout > 0) {
    const to = window.setTimeout(() => {
      setToasts?.(toasts => {
        return toasts.filter(x => x.id !== currentId);
      });
      timeoutMap.delete(to);
    }, timeout);
    timeoutMap.set(currentId, to);
  }
}

export function errorToast(error: Error) {
  return toast(
    <div>
      <h3 className="mb-4 text-center text-xl">🙀🔥 Unerwarteter Fehler 🔥🙀</h3>
      <p>{error.message}</p>
    </div>,
    'error',
    0
  );
}
