import { joinClasses } from '@/lib/utils';

const badgeColors = {
  yellow: 'bg-yellow-300 border-yellow-500 text-yellow-800',
  fuchsia: 'bg-fuchsia-300 border-fuchsia-500 text-fuchsia-800'
} as const;

export default function Badge({
  children,
  color,
  className
}: {
  children: React.ReactNode;
  color: keyof typeof badgeColors;
  className?: string;
}) {
  return (
    <span
      className={joinClasses(
        'px-1.5 py-0.5 rounded-full text-sm shadow-sm font-semibold border',
        badgeColors[color],
        className
      )}
    >
      {children}
    </span>
  );
}
