import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useLoggedInUser } from '@/lib/apiEndpoints';
import { ErrorMessage } from './ErrorMessage';
import LoadingPlaceholder from './LoadingPlaceholder';

export default function AuthenticatedWrapper({ children }: PropsWithChildren): React.ReactElement {
  const { data: user, error, fetchStatus } = useLoggedInUser();

  const location = useLocation();
  const pathname = location.pathname;
  const redirectTo = pathname === '/login' || pathname === '/' ? undefined : pathname;

  if (fetchStatus === 'fetching') {
    return <LoadingPlaceholder />;
  } else if (error) {
    return <ErrorMessage error={error as Error} />;
  } else if (user) {
    return <>{children}</>;
  } else {
    return (
      <Navigate
        to={{ pathname: '/login', search: redirectTo && `?redirect=${redirectTo}` }}
        replace
      />
    );
  }
}
