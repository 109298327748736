import { Link } from 'react-router-dom';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import QuestionMarkCircleIcon from '@heroicons/react/24/outline/QuestionMarkCircleIcon';
import { useUnits } from '@/lib/apiEndpoints';
import { SEV_OBJECTS, JPW_OBJECTS } from '../constants';
import Badge from './Badge';
import { CardWTitle } from './Layout';

export default function MyServices({ className }: { className?: string }) {
  const { data: units } = useUnits();
  const hasSev = !units || units.some(x => x.isSev);
  const hasJpw = !units || units.some(x => x.isJpw);

  const sevAvailable = hasSev || units?.some(x => SEV_OBJECTS.includes(x.objectNumber));
  const jpwAvailable = hasJpw || units?.some(x => JPW_OBJECTS.includes(x.objectNumber));

  const someAvailable = sevAvailable || jpwAvailable;

  return (
    <CardWTitle className={className} title="Meine Services">
      <div className="flex flex-col gap-4">
        {sevAvailable && (
          <div className="flex flex-col gap-2">
            <Link to="/sev">
              <Badge color="yellow">Sondereigentumsverwaltung (SEV)</Badge>
            </Link>
            <Link to="/sev" className="text-primary-900 underline">
              <InformationCircleIcon className="w-6 h-6 inline mr-1 align-top" />
              Weitere Informationen zur Sondereigentumsverwaltung
            </Link>
            <Link to="/sev-faq" className="text-primary-900 underline">
              <QuestionMarkCircleIcon className="w-6 h-6 inline mr-1 align-top" />
              Häufig gestellte Fragen
            </Link>
            {!hasSev && (
              <p className="text-red-800">Sie haben noch keinen SEV Vertrag abgeschlossen.</p>
            )}
          </div>
        )}
        {jpwAvailable && (
          <div className="flex flex-col gap-2">
            <Link to="/pflichtwartung">
              <Badge color="fuchsia">Pflichtwartung</Badge>
            </Link>
            <Link to="/pflichtwartung" className="text-primary-900 underline">
              <InformationCircleIcon className="w-6 h-6 inline mr-1 align-top" />
              Weitere Informationen zu den Pflichtwartungen
            </Link>
            <Link to="/pflichtwartung-faq" className="text-primary-900 underline">
              <QuestionMarkCircleIcon className="w-6 h-6 inline mr-1 align-top" />
              Häufig gestellte Fragen
            </Link>
            {!hasJpw && (
              <p className="text-red-800">Sie haben noch keine Pflichtwartungen gebucht.</p>
            )}
          </div>
        )}
        {someAvailable && (
          <div>
            Unter <b>Meine Einheiten</b> sehen Sie unter jedem Eintrag, welche Dienste Sie gebucht
            haben.
          </div>
        )}
      </div>
    </CardWTitle>
  );
}
