import { format, parse } from 'date-fns';
import deLocale from 'date-fns/locale/de';

export function parseDate(dateStringHuman: string) {
  if (dateStringHuman === '') {
    return null;
  }
  if (!/\d\d\.\d\d\.\d\d\d\d/.test(dateStringHuman)) {
    throw new Error('invalid date regex');
  }
  const parsed = parse(dateStringHuman, 'dd.MM.yyyy', new Date(0));
  return format(parsed, 'yyyy-MM-dd');
}

export function parseDateTime(dateStringHuman: string) {
  if (dateStringHuman === '') {
    return null;
  }
  if (!/\d\d\.\d\d\.\d\d\d\d \d\d:\d\d/.test(dateStringHuman)) {
    throw new Error('invalid datetime regex');
  }
  const parsed = parse(dateStringHuman, 'dd.MM.yyyy HH:mm', new Date(0));
  return format(parsed, 'yyyy-MM-dd HH:mm');
}

export function formatDate(dateString: Date | string | null) {
  return dateString ? format(new Date(dateString), 'dd.MM.yyyy') : '';
}

export function formatDateTime(dateString: Date | string | null) {
  return dateString ? format(new Date(dateString), 'dd.MM.yyyy HH:mm') : '';
}

export function formatLocale(date: Date, dateFormat: string) {
  return format(date, dateFormat, { locale: deLocale });
}
