import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { joinClasses } from '@/lib/utils';
import { IconType } from '@/types/type-utils';
import Tooltip from './Tooltip';

export const INPUT_CLASSNAME = [
  'rounded-sm w-full py-2 px-2 appearance-none text-gray-800 leading-tight',
  'border border-gray-400 disabled:text-gray-400 shadow-sm',
  'focus:outline-none focus:border-b-4 mb-[3px] focus:mb-0 focus:border-b-primary-600'
].join('\n');

const BUTTON_CLASSES =
  'px-4 py-2 text-sm shadow-md hover:brightness-95 hover:saturate-150 disabled:cursor-default rounded-sm';

const BUTTON_VARIANTS = {
  default: 'border border-gray-400 bg-gray-200 text-gray-800',
  primary: 'border border-gray-400 bg-primary-300 text-gray-800 disabled:bg-gray-200',
  green: 'border border-gray-400 bg-green-300 text-gray-800',
  red: 'border border-red-400 bg-red-300 text-gray-800',
  inverted: 'bg-primary-700 text-white'
} as const;

export const Button = React.forwardRef(function Button(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: keyof typeof BUTTON_VARIANTS;
  },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { children, className, variant = 'default', ...rest } = props;

  return (
    <button
      ref={ref}
      className={joinClasses(BUTTON_CLASSES, variant && BUTTON_VARIANTS[variant], className)}
      {...rest}
    >
      {children}
    </button>
  );
});

export const LinkButton = React.forwardRef(function LinkButton(
  props: LinkProps & { variant?: keyof typeof BUTTON_VARIANTS },
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { className, variant = 'default', ...rest } = props;
  return (
    <Link
      className={joinClasses(
        BUTTON_CLASSES,
        'text-center',
        variant && BUTTON_VARIANTS[variant],
        className
      )}
      {...rest}
      ref={ref}
    />
  );
});

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon: IconType;
  small?: boolean;
};

function _IconButton(props: IconButtonProps) {
  const { className, title, Icon, small = false, ...rest } = props;

  return (
    <button
      type="button"
      className={joinClasses(
        'rounded-full bg-gray-600 text-white transition-transform hover:scale-125',
        'ring-amber-300 focus:ring',
        className
      )}
      title={title}
      {...rest}
    >
      <Icon className={small ? 'h-4 w-4' : 'h-6 w-6'} />
    </button>
  );
}

export function IconButton(props: IconButtonProps) {
  if (props.title) {
    return (
      <Tooltip title={props.title}>
        <_IconButton {...props} />
      </Tooltip>
    );
  } else {
    return <_IconButton {...props} />;
  }
}

type FieldStatus = {
  hasError?: boolean;
  hasWarning?: boolean;
};

function errorWarningClass(hasError: boolean, hasWarning: boolean) {
  return hasError
    ? 'bg-red-200 placeholder:text-gray-600'
    : hasWarning
      ? 'bg-yellow-300 placeholder:text-gray-600'
      : 'bg-gray-100 focus:bg-gray-50';
}

export const Input = React.forwardRef(function Input(
  {
    className,
    hasError = false,
    hasWarning = false,
    ...rest
  }: React.InputHTMLAttributes<HTMLInputElement> & FieldStatus,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      className={joinClasses(INPUT_CLASSNAME, errorWarningClass(hasError, hasWarning), className)}
      {...rest}
    />
  );
});

export const Select = React.forwardRef(function Select(
  {
    className,
    hasError = false,
    hasWarning = false,
    ...rest
  }: React.SelectHTMLAttributes<HTMLSelectElement> & FieldStatus,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  return (
    <select
      className={joinClasses(INPUT_CLASSNAME, errorWarningClass(hasError, hasWarning), className)}
      ref={ref}
      {...rest}
    />
  );
});

export const Textarea = React.forwardRef(function Textarea(
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement> & FieldStatus,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  const { className, hasError = false, hasWarning = false, ...rest } = props;

  return (
    <textarea
      ref={ref}
      className={joinClasses(INPUT_CLASSNAME, errorWarningClass(hasError, hasWarning), className)}
      {...rest}
    />
  );
});

export function FormRow({
  children,
  className,
  icon
}: {
  children: [React.ReactNode, React.ReactNode];
  labelWidth?: string;
  className?: string;
  icon?: React.ReactNode;
}) {
  return (
    <div className={joinClasses(className)}>
      <div className="mb-1">{children[0]}</div>
      <div className="relative">
        {children[1]}
        {icon && <div className="absolute inset-y-0 right-0 flex items-center pr-1">{icon}</div>}
      </div>
    </div>
  );
}

export function Label({ className, ...rest }: React.LabelHTMLAttributes<HTMLLabelElement>) {
  return (
    <label className={joinClasses('text-gray-800', className)} title={rest.htmlFor} {...rest} />
  );
}

export function Card({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={joinClasses('rounded-sm border bg-white p-4 shadow-sm flex flex-col', className)}
    >
      {children}
    </div>
  );
}

export function CardWTitle({
  children,
  className,
  title
}: PropsWithChildren<{ className?: string; title: React.ReactNode }>) {
  return (
    <div
      className={joinClasses('flex flex-col p-2 rounded-sm border bg-white shadow-sm', className)}
    >
      <h2 className="mb-2 border-l-4 border-primary-600 pl-1 text-lg font-bold text-gray-600">
        {title}
      </h2>
      {children}
    </div>
  );
}

export const FloatingInput = React.forwardRef(function _FloatingInput(
  {
    placeholder,
    className,
    hasError = false,
    ...inpProps
  }: React.InputHTMLAttributes<HTMLInputElement> & { hasError?: boolean },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div
      className={joinClasses(
        'flex items-center border-b pb-px border-gray-400 transition-colors',
        'focus-within:border-primary-600 focus-within:border-b-2 focus-within:pb-0',
        hasError && '!border-red-400',
        className
      )}
    >
      <div className="relative w-full">
        <input
          className="peer w-full appearance-none border-0 bg-transparent px-1 pb-2 pt-5 placeholder:text-transparent focus:outline-none"
          placeholder={placeholder}
          ref={ref}
          {...inpProps}
        />
        <label
          htmlFor={inpProps.id}
          className={joinClasses(
            'absolute top-5 left-1 z-10 origin-left -translate-y-6 scale-90 text-gray-600',
            'duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100',
            'pointer-events-none peer-focus:-translate-y-6 peer-focus:scale-90 peer-focus:text-primary-900',
            hasError && '!text-red-600'
          )}
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
});
