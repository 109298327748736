import { CardWTitle } from '@/components/Layout';
import { useAllFiles } from '@/lib/apiEndpoints';
import { DocumentsList } from './DocsUtils';
import QueryWrapper from './QueryWrapper';

export default function NewDocuments({ className }: { className?: string }) {
  const { data, error } = useAllFiles(10);

  return (
    <CardWTitle title="neue Dokumente" className={className}>
      <QueryWrapper data={data} error={error}>
        {data => <DocumentsList items={data} />}
      </QueryWrapper>
    </CardWTitle>
  );
}
