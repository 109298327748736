import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { UnitList } from '@/components/user/Units';
import { useUnits } from '@/lib/apiEndpoints';

export default function UnitsPage() {
  const { data, error } = useUnits();
  return (
    <PageContainer>
      <PageHeader>Meine Einheiten</PageHeader>
      <QueryWrapper data={data} error={error}>
        {units => <UnitList units={units} />}
      </QueryWrapper>
    </PageContainer>
  );
}
