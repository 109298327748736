import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postNavigate } from './apiEndpoints';

export default function useNavigation() {
  const location = useLocation();

  useEffect(() => {
    postNavigate({
      location: location.pathname
    });
  }, [location]);
}
