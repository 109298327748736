import React, { useState, useRef, useLayoutEffect } from 'react';
import { joinClasses } from '@/lib/utils';

export default function ResponsiveContainer({
  children,
  className,
  ratio = 0,
  maxHeight = 0
}: {
  children: (x: { width: number; height: number }) => React.ReactNode;
  className?: string;
  ratio?: number;
  maxHeight?: number;
}) {
  const [dims, setWidthHeight] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const container = containerRef.current!;
    let aFrameId = 0;

    const resizeObserver = new ResizeObserver(([entry]) => {
      const { width, height } = entry.contentRect;
      window.cancelAnimationFrame(aFrameId);
      aFrameId = window.requestAnimationFrame(() => {
        setWidthHeight({ width, height });
      });
    });
    resizeObserver.observe(container);
    return () => {
      window.cancelAnimationFrame(aFrameId);
      resizeObserver.disconnect();
    };
  }, []);

  const style = ratio
    ? { paddingTop: maxHeight ? `min(${100 / ratio}%, ${maxHeight})` : `${100 / ratio}%` }
    : undefined;

  return (
    <div className={joinClasses('relative', className)} style={style}>
      <div className="absolute inset-0" ref={containerRef}>
        {dims.width > 0 && children(dims)}
      </div>
    </div>
  );
}
