import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { joinClasses } from '@/lib/utils';

const PORTAL_CONTAINER_ID = 'portal-container';
let count = 0;

function PortalContainer({ children }: { children: React.ReactNode }) {
  const container = useMemo(() => {
    count++;
    let elem = document.getElementById(PORTAL_CONTAINER_ID);
    if (!elem) {
      elem = document.createElement('div');
      document.body.appendChild(elem);
      elem.id = PORTAL_CONTAINER_ID;
    }
    return elem;
  }, []);

  useEffect(
    () => () => {
      count--;
      if (count === 0) {
        document.body.removeChild(container);
      }
    },
    [container]
  );

  return ReactDOM.createPortal(children, container);
}

const MARGIN_X = 20;

function AutoFixed({
  x: targetX,
  y: targetY,
  className,
  children
}: {
  x: number;
  y: number;
  className?: string;
  children: React.ReactNode;
}) {
  const elemRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<React.CSSProperties>({ left: targetX, top: targetY });

  useLayoutEffect(() => {
    const { width: elemWidth, height: elemHeight } = elemRef.current!.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let newX = targetX + MARGIN_X;
    let newY = targetY;
    if (newX + MARGIN_X + elemWidth > windowWidth) {
      newX = targetX - MARGIN_X - elemWidth;
    }
    if (newY + elemHeight > windowHeight) {
      newY = windowHeight - elemHeight;
    }
    if (newX !== targetX || newY !== targetY) {
      setStyle({ left: newX, top: newY });
    }
  }, [targetX, targetY]);

  return (
    <PortalContainer>
      <div
        className={joinClasses('pointer-events-none fixed z-50 w-max', className)}
        ref={elemRef}
        style={style}
      >
        {children}
      </div>
    </PortalContainer>
  );
}

export default function Tooltip({
  title,
  children,
  delay = 100,
  popupClassName = 'px-2 py-1 bg-gray-800 text-gray-200 shadow-md rounded-md'
}: {
  title: React.ReactNode;
  children: React.ReactElement;
  delay?: number;
  popupClassName?: string;
}) {
  const timeoutId = useRef(-1);
  const mPosRef = useRef({ x: 0, y: 0 });
  const [pos, setPos] = useState<{ x: number; y: number } | null>(null);
  const child = React.Children.only(children);

  const mouseEnter = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => {
      setPos({ x: mPosRef.current.x, y: mPosRef.current.y });
    }, delay);
  };

  const mouseMove = (e: React.MouseEvent) => {
    mPosRef.current.x = e.clientX;
    mPosRef.current.y = e.clientY;
    if (pos) {
      setPos({ x: e.clientX, y: e.clientY });
    }
  };

  const mouseLeave = () => {
    window.clearTimeout(timeoutId.current);
    setPos(null);
  };

  useEffect(
    () => () => {
      window.clearTimeout(timeoutId.current);
    },
    []
  );

  return (
    <>
      {pos && (
        <AutoFixed x={pos.x} y={pos.y} className={popupClassName}>
          {title}
        </AutoFixed>
      )}
      {React.cloneElement(child, {
        onMouseEnter: mouseEnter,
        onMouseLeave: mouseLeave,
        onMouseMove: mouseMove,
        title: pos ? '' : child.props.title
      })}
    </>
  );
}
