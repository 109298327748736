import React, { Fragment, RefObject, useCallback, useContext, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { joinClasses } from '@/lib/utils';
import { IconButton } from './Layout';

type VoidFn = () => void;
const voidFn = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

const ModalContext = React.createContext<RefObject<HTMLDivElement> | null>(null);
export function useModalContainer(): React.RefObject<HTMLDivElement> | null {
  return useContext(ModalContext);
}

export default function Modal({
  isOpen,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal = voidFn,
  children,
  blur = false,
  position = 'center',
  showClose = false,
  wrapperClassName
}: {
  isOpen: boolean;
  closeModal?: VoidFn;
  children: React.ReactNode;
  blur?: boolean;
  position?: 'center' | 'top';
  showClose?: boolean;
  wrapperClassName?: string;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={joinClasses(
          'fixed inset-0 z-40 flex justify-center',
          blur && 'bg-gray-400/50 backdrop-blur-sm',
          position === 'center' ? 'items-center' : 'items-start'
        )}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={joinClasses(
              'relative my-8 text-left transition-all',
              position === 'top' && 'mt-14',
              wrapperClassName
            )}
          >
            <div
              className={joinClasses(
                'overflow-hidden rounded border border-gray-300 bg-white shadow-xl',
                wrapperClassName
              )}
            >
              <div
                ref={containerRef}
                className="overflow-auto p-4 text-left
                           max-w-[calc(100vw-1rem)] max-h-[calc(100vh-1rem)]
                           md:max-w-[calc(100vw-4rem)] md:max-h-[calc(100vh-4rem)]"
              >
                <ModalContext.Provider value={containerRef}>{children}</ModalContext.Provider>
              </div>
            </div>
            {showClose && (
              <IconButton
                Icon={XMarkIcon}
                className="absolute -top-3 -right-3 z-50"
                onClick={closeModal}
                title="Schließen"
              />
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export function ToggleModal({
  initialShown = false,
  children,
  render,
  showClose = true,
  blur = true
}: {
  initialShown?: boolean;
  children: (openModal: () => void) => React.ReactElement;
  render: (closeModal: () => void) => React.ReactNode;
  showClose?: boolean;
  blur?: boolean;
}) {
  const [isShown, setIsShown] = useState(initialShown);
  const closeModal = useCallback(() => setIsShown(false), []);

  return (
    <>
      {children(() => setIsShown(true))}
      <Modal isOpen={isShown} closeModal={closeModal} showClose={showClose} blur={blur}>
        {render(closeModal)}
      </Modal>
    </>
  );
}
