import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, FloatingInput } from '@/components/Layout';
import Spinner from '@/components/Spinner';
import { postApi } from '@/lib/api';
import { useLoggedInUser } from '@/lib/apiEndpoints';
import { toast } from '@/lib/toast';

type ChangePasswordData = { oldPassword: string; newPassword: string; newPasswordConfirm: string };

const defaultValues: ChangePasswordData = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export default function ChangePassword({ redirectUrl }: { redirectUrl?: string }) {
  const { data: user } = useLoggedInUser();
  const navigate = useNavigate();
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors }
  } = useForm<ChangePasswordData>({ mode: 'onChange', defaultValues: defaultValues });

  const mutation = useMutation(
    async (data: ChangePasswordData) => {
      await postApi('/api/change_password', data);
    },
    {
      onSuccess: () => {
        reset(defaultValues);
        document.getElementById('oldPassword')?.focus();
        toast('Ihr Passwort wurde erfolgreich geändert', 'success');
        if (redirectUrl) {
          navigate(redirectUrl, { replace: true });
        }
      }
    }
  );

  const isLoading = mutation.isLoading;

  const onSubmit = (data: ChangePasswordData) => {
    if (isLoading) {
      return;
    }
    mutation.mutate(data);
  };

  return (
    <form
      className="flex w-full max-w-sm flex-col gap-4 space-y-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="hidden">
        <label htmlFor="userName">Login</label>
        <input type="text" autoComplete="user" disabled value={user?.login} />
      </div>
      <FloatingInput
        id="oldPassword"
        autoFocus={true}
        type="password"
        autoComplete="current-password"
        placeholder="altes Passwort"
        hasError={!!errors.oldPassword}
        {...register('oldPassword', { required: true })}
      />
      <FloatingInput
        id="newPassword"
        type="password"
        autoComplete="new-password"
        placeholder="neues Passwort"
        hasError={!!errors.newPassword}
        {...register('newPassword', {
          required: true,
          minLength: {
            value: 6,
            message: 'Ihr neues Passwort muss eine Mindestlänge von 6 Zeichen haben.'
          }
        })}
      />
      {errors.newPassword && (
        <div className="text-sm text-red-600">{errors.newPassword.message}</div>
      )}
      <FloatingInput
        id="newPasswordConfirm"
        type="password"
        autoComplete="new-password"
        placeholder="neues Passwort bestätigen"
        hasError={!!errors.newPasswordConfirm}
        {...register('newPasswordConfirm', {
          required: true,
          validate: newPasswordConfirm =>
            newPasswordConfirm === getValues('newPassword') || 'Neues Passwort stimmt nicht überein'
        })}
      />
      {errors.newPasswordConfirm && (
        <div className="text-sm text-red-600">{errors.newPasswordConfirm.message}</div>
      )}
      <div>
        <Button
          type="submit"
          value="Login"
          className="w-full"
          variant={isValid ? 'primary' : 'default'}
          disabled={isLoading || !isValid}
        >
          {isLoading ? <Spinner className="inline-block" /> : 'Passwort ändern'}
        </Button>
        {!!mutation.error && (
          <div className="m-2 p-2 rounded text-red-800 bg-red-50 border border-red-400">
            Ihr Passwort konnte nicht geändert werden. Bitte überprüfen Sie, ob Sie Ihr altes
            Passwort richtig eingegeben haben.
          </div>
        )}
        {mutation.isSuccess && (
          <div className="m-2 p-2 rounded text-green-800 bg-green-50 border border-green-400">
            Ihr Passwort wurde erfolgreich geändert
          </div>
        )}
      </div>
    </form>
  );
}
