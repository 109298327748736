import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon';
import { useLogout } from '@/lib/userUtils';
import { User } from '@/shared/types';
import { ContactLabel } from './ContactLabel';
import { Dropdown } from './Overlays';

export function UserSettings({ user }: { user: User }) {
  const logout = useLogout();

  if (!user) {
    return null;
  }

  return (
    <Dropdown
      position="left"
      button={() => (
        <Dropdown.Button className="flex h-full items-center gap-2">
          <ContactLabel contact={user} limit={1} />
          <UserCircleIcon className="shrink-0 h-6 w-6 text-gray-500 transition-transform hover:scale-125" />
        </Dropdown.Button>
      )}
    >
      {user && (
        <div className="mb-5 inline-block whitespace-nowrap p-2 text-center text-sm text-gray-800">
          <span className="font-bold">
            <ContactLabel contact={user} />
          </span>
        </div>
      )}
      <Dropdown.ButtonItem onClick={logout}>Logout</Dropdown.ButtonItem>
    </Dropdown>
  );
}
