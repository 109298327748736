import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import UsersIcon from '@heroicons/react/24/outline/UsersIcon';
import { useUnits } from '@/lib/apiEndpoints';
import { formatDate } from '@/lib/dateUtils';
import { joinClasses } from '@/lib/utils';
import { UnitData, UnitTennant } from '@/shared/types';
import Badge from '../Badge';
import { CardWTitle } from '../Layout';
import LoadingPlaceholder from '../LoadingPlaceholder';
import Tooltip from '../Tooltip';

function ContractDuration({
  start,
  end,
  className
}: {
  start: string;
  end: string | null;
  className?: string;
}) {
  if (end) {
    return (
      <span className={className}>
        Mietbeginn {formatDate(start)}
        <br />
        Mietende {formatDate(end)}
      </span>
    );
  } else {
    return <span className={className}>Mietbeginn {formatDate(start)}</span>;
  }
}

function Tennant({ tennant }: { tennant: UnitTennant }) {
  return (
    <div className="text-right">
      <div>
        <UsersIcon className="inline w-4 h-4 -translate-y-0.5 text-gray-400 flex-none mr-1" />
        <span className="text-gray-600">{tennant.identityLabel}</span>
      </div>
      <div>
        <ContractDuration
          start={tennant.contract_start!}
          end={tennant.contract_end}
          className="text-sm text-gray-400"
        />
      </div>
    </div>
  );
}

function isTennantActive(tennant: UnitTennant) {
  const now = Date.now();
  return (
    +new Date(tennant.contract_start) < now &&
    (tennant.contract_end === null || now < +new Date(tennant.contract_end))
  );
}

function hasServices(unit: UnitData) {
  return unit.isSev || unit.isJpw;
}

function UnitBadges({ unit }: { unit: UnitData }) {
  return (
    <>
      {unit.isSev && <Badge color="yellow">SEV</Badge>}
      {unit.isJpw && <Badge color="fuchsia">Pflichtwartung</Badge>}
    </>
  );
}

export function UnitCard({
  unit,
  showAllTennants = false,
  showInfo = false
}: {
  unit: UnitData;
  showInfo?: boolean;
  showAllTennants?: boolean;
}) {
  const tennants = useMemo(() => {
    if (!unit.tennants) {
      return [];
    }
    if (!showAllTennants) {
      return unit.tennants.filter(isTennantActive);
    }
    return unit.tennants;
  }, [unit.tennants, showAllTennants]);

  return (
    <div className="flex justify-between gap-4 px-1">
      <div className="flex flex-col gap-2 items-start">
        <div>
          <h3
            className={joinClasses('text-primary-900 flex gap-2 text-lg', showInfo && 'underline')}
            title={`${unit.objectNumber} ${unit.unitNumber}`}
          >
            {unit.unitLabel}
            {showInfo && (
              <Tooltip title="Weitere Informationen">
                <InformationCircleIcon className="h-6 w-6 text-gray-600 translate-y-0.5" />
              </Tooltip>
            )}
          </h3>
          <div className="text-sm text-gray-600">{unit.objectLabel}</div>
        </div>
        {hasServices(unit) ? (
          <div className="flex gap-2 items-center">
            <div className="font-bold text-sm text-gray-400">Services</div>
            <UnitBadges unit={unit} />
          </div>
        ) : (
          <div className="font-bold text-sm text-gray-400">keine Services gebucht</div>
        )}
      </div>
      <div>
        {unit.isSev &&
          tennants?.length > 0 &&
          tennants.map(tennant => <Tennant key={tennant.identityId} tennant={tennant} />)}
      </div>
    </div>
  );
}

export function UnitList({
  units,
  showAllTennants = false
}: {
  units: UnitData[];
  showAllTennants?: boolean;
}) {
  return (
    <div className="flex flex-col">
      {units.map(unit => {
        return (
          <div
            key={unit.id}
            className="py-4 first:pt-2 border-dashed border-b last:border-b-0 border-gray-300"
          >
            <Link to={`/einheiten/${unit.objectNumber}-${unit.unitNumber}`}>
              <UnitCard unit={unit} showAllTennants={showAllTennants} showInfo />
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export function UnitsCard({ className }: { className?: string }) {
  const { data: units } = useUnits();

  return (
    <CardWTitle className={className} title="Meine Einheiten">
      {units ? <UnitList units={units} /> : <LoadingPlaceholder />}
    </CardWTitle>
  );
}
