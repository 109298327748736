import { useParams } from 'react-router-dom';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { useConfirmCommunicationPreference } from '@/lib/apiEndpoints';

export default function ConfirmCommunicationPreferencePage() {
  const { token } = useParams<{ token: string }>();
  const { data, error } = useConfirmCommunicationPreference(token!);

  return (
    <PageContainer>
      <PageHeader>Bestätigung Korrespondenz per E-Mail</PageHeader>
      <QueryWrapper data={data} error={error}>
        {() => (
          <div>
            <h2 className="text-lg mb-2">Hiermit ist der Vorgang abgeschlossen</h2>
            <p className="mb-2">
              Wir überprüfen Ihre Angaben und stellen den Versand der Korrespondenz auf E-Mail um.
            </p>
          </div>
        )}
      </QueryWrapper>
    </PageContainer>
  );
}
