import { Link } from 'react-router-dom';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { CardWTitle, IconButton } from '@/components/Layout';

export default function InfoCard({ closeInfo }: { closeInfo: () => void }) {
  return (
    <CardWTitle
      title="Willkommen im Eigentümerportal der Immobilienverwaltung Riebeling."
      className="relative"
    >
      <p className="mb-4">
        Hier steht Ihnen eine umfangreiche Auswahl an Dokumenten, wie Jahresabrechnungen und
        Versicherungsunterlagen, zum Download bereit. Kunden der <b>Sondereigentumsverwaltung</b>{' '}
        profitieren von einer übersichtlichen Darstellung aller wichtigen Informationen.
        <br />
        Unter{' '}
        <Link to="/einheiten" className="text-secondary-800 underline">
          Meine Einheiten
        </Link>{' '}
        finden Sie Buchungsblätter für Mieterträge und Mietauszahlungen, um Ihre finanziellen
        Transaktionen stets im Blick zu behalten. <br />
        Bei Fragen oder Anliegen können Sie über das{' '}
        <Link to="/kontaktformular" className="text-secondary-800 underline">
          Kontaktformular
        </Link>{' '}
        unkompliziert mit unserem Team in Verbindung treten, das Ihnen bei allen Belangen rund um
        Ihre Immobilien zur Verfügung steht.
      </p>

      <IconButton
        Icon={XMarkIcon}
        className="absolute top-1 right-1"
        onClick={closeInfo}
        title="Schließen"
      />
    </CardWTitle>
  );
}
