import DocumentArrowDownIcon from '@heroicons/react/24/outline/DocumentArrowDownIcon';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import { globalFileDownloadLink, useGlobalFiles } from '@/lib/apiEndpoints';
import { basename } from '@/lib/utils';
import { GlobalDoc } from '@/shared/types';

function GlobalDocsList({ data }: { data: GlobalDoc[] }) {
  return (
    <ul className="flex flex-col divide-y ">
      {data.map(elem => (
        <li
          key={elem.filename}
          className="flex flex-col gap-2 border-dashed border-gray-300 py-2 pr-1"
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={globalFileDownloadLink(elem.filename)}
            download
            className="flex items-center gap-2 border-l-2 border-primary-400 pl-2 font-bold text-gray-600"
          >
            <span className="flex-1">{elem.title || basename(elem.filename)}</span>
            <DocumentArrowDownIcon className="h-5 w-5 shrink-0 text-primary-600 transition-transform hover:scale-125" />
          </a>
          {elem.content && (
            <div dangerouslySetInnerHTML={{ __html: elem.content }} className="prose" />
          )}
        </li>
      ))}
    </ul>
  );
}

export default function GlobalDocsPage() {
  const { data, error } = useGlobalFiles();

  return (
    <PageContainer>
      <PageHeader>Allgemeine Informationen</PageHeader>
      <QueryWrapper data={data} error={error}>
        {data => <GlobalDocsList data={data} />}
      </QueryWrapper>
    </PageContainer>
  );
}
