import { BBResult } from './types';

function formatNumber(value: unknown) {
  if (value == null) {
    return '';
  }
  if (typeof value === 'number') {
    let valFixed = value.toFixed(2);
    if (valFixed === '-0.00') {
      valFixed = '0.00';
    }

    return `${valFixed.replace('.', ',')}`;
  }
  return String(value);
}

export function formatCurrency(amount: number | undefined) {
  if (amount == null) {
    return '';
  }

  const noString = formatNumber(amount);
  return `${noString}€`;
}

export function sum(values: (number | undefined)[]) {
  values = values.filter(x => typeof x === 'number');
  if (values.length === 0) {
    return 0;
  }
  return (values as number[]).reduce((acc, cur) => acc + cur, 0);
}

export const EPS = 1e-6;

export type MonthRange = {
  dateString: string;
  label: string;
}[];

const monthLabels = [
  'Jan',
  'Feb',
  'Mär',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez'
];

export function createMonthRange(year: number): MonthRange {
  const months = [...Array(12).keys()].map(i => i);

  return months.map(i => {
    return {
      dateString: `${year}-${(i + 1).toString().padStart(2, '0')}`,
      label: monthLabels[i] + ' ' + year.toString().slice(-2)
    };
  });
}

function filterRecordByYear<T extends Record<string, number | unknown[]>>(
  record: Record<string, T>,
  year: number
): Record<string, T> {
  const yearStr = year.toString();
  return Object.fromEntries(
    Object.entries(record).filter(([key, value]) => {
      if (!key.startsWith(yearStr)) {
        return false;
      }

      return Object.values(value).some(v =>
        typeof v === 'number' ? Math.abs(v) >= EPS : v.length > 0
      );
    })
  ) as Record<string, T>;
}

function sumRows(rows: number[][]) {
  const sums: number[] = [];
  for (let i = 0; i < rows[0].length; i++) {
    sums.push(sum(rows.map(x => x[i]))!);
  }
  return sums;
}

export function getBucketVal<K extends string>(
  bucket: Record<K, Record<string, { value: number }>>,
  tag: K,
  date: string
): number {
  return bucket[tag]?.[date]?.value || 0;
}

function getBucketValsSum<K extends string>(
  bucket: Record<K, Record<string, { value: number }>>,
  tags: K[],
  date: string
): number {
  return tags.reduce((acc, tag) => acc + getBucketVal(bucket, tag, date), 0);
}

export type TableRowData = {
  label: string;
  title?: string;
  labelPadding?: number;
  hasSum?: boolean;
  values: number[];
  valueLabels?: (string | undefined)[];
  showZeros?: boolean;
  valueClassName?: string;
};

function mapSaldoData(mieterDataStripped: BBResult['mieterData'], monthRange: MonthRange) {
  const saldoTotal = Object.fromEntries(monthRange.map(x => [x.dateString, 0]));

  const mieterData = mieterDataStripped.map(m => {
    // let prevSaldo = m.mieterSaldoYear[year - 1]; // startsaldo, endsaldo of previous year
    let prevSaldo = m.cstart; // startsaldo, endsaldo of previous year
    const saldoData = Object.fromEntries(
      monthRange.map(mr => {
        const dateString = mr.dateString;
        const currentSaldo = prevSaldo + (m.mieterSaldoMonth[dateString] || 0);

        // const miete = m.miete[x.dateString];
        // const zahlungen = m.zahlungen[x.dateString];
        // const sonstiges = m.sonstiges[x.dateString];

        // // TODO this differs from saldo in api by 'sollst.abr -= mgb.BETRAG;'
        // let currentSaldo = prevSaldo;
        // currentSaldo += miete?.miete || 0;
        // currentSaldo += miete?.nk || 0;
        // currentSaldo += miete?.tg || 0;
        // currentSaldo += miete?.abr || 0;
        // currentSaldo += miete?.abrErlmin || 0;
        // currentSaldo -= zahlungen?.gesamt || 0;
        // currentSaldo += sonstiges?.betrag || 0;

        prevSaldo = currentSaldo;
        saldoTotal[dateString] += currentSaldo;

        return [dateString, currentSaldo];
      })
    );
    return {
      mieter: m.mieter,
      saldoData
    };
  });

  return {
    mieterData: mieterData,
    saldoTotal
  };
}

function formatTennantName(name: string) {
  if (name === '[Leerstandsadresse]') {
    return 'Leerstand';
  }
  return name;
}

function createOwnerRows(data: BBResult, monthRange: MonthRange) {
  const sevBuckets = data.sevData.buckets;
  const mietguts = monthRange.map(
    mr => -(getBucketVal(sevBuckets, 'mietguts', mr.dateString) || 0)
  );

  const kostenRows = monthRange.map(mr => {
    const vers = getBucketVal(sevBuckets, 'kostenVersicherung', mr.dateString);
    const sev = getBucketVal(sevBuckets, 'kostenVerwaltung', mr.dateString);
    const sonder = getBucketVal(sevBuckets, 'kostenSonderkosten', mr.dateString);

    const sonderItems = sevBuckets['kostenSonderkosten']?.[mr.dateString]?.items || [];

    return {
      vers,
      sev,
      sonder,
      sonderText: sonderItems.map(x => `${formatNumber(x.BETRAG)}€ ${x.BUCHUNGSTEXT}`),
      total: vers + sev + sonder
    };
  });

  const auszRows = monthRange.map(mr => {
    return getBucketVal(sevBuckets, 'kostenAuszahlung', mr.dateString);
  });

  let lastSaldo = data.sevData.sevStartSaldo;

  const saldoRows = monthRange.map((mr, idx) => {
    const kosten = kostenRows[idx].total;
    const miet = getBucketVal(sevBuckets, 'mietguts', mr.dateString); // this is negative
    let saldo = lastSaldo - miet - kosten;
    if (idx > 0) {
      saldo -= auszRows[idx - 1];
    }
    lastSaldo = saldo;
    return saldo;
  });

  // auszahlung + kosten - mietguts -> übertrag
  const ubertragRows = monthRange.map(mr => {
    const mietguts = getBucketVal(sevBuckets, 'mietguts', mr.dateString); // this is negative

    const sonderkosten = getBucketVal(sevBuckets, 'kostenSonderkosten', mr.dateString);
    const verwaltung = getBucketVal(sevBuckets, 'kostenVerwaltung', mr.dateString);
    const versicherung = getBucketVal(sevBuckets, 'kostenVersicherung', mr.dateString);
    const kostenSum = sonderkosten + verwaltung + versicherung;

    const auszahlung = getBucketVal(sevBuckets, 'kostenAuszahlung', mr.dateString);
    return kostenSum + mietguts + auszahlung;
  });

  const ownerRows: TableRowData[] = [
    { label: 'Mietgutschriften', hasSum: true, values: mietguts, showZeros: true },
    {
      label: 'Kosten Eigentümer',
      hasSum: true,
      values: kostenRows.map(x => x.total)
    },
    {
      label: 'Versicherungen',
      labelPadding: 1,
      hasSum: true,
      values: kostenRows.map(x => x.vers)
    },
    {
      label: 'Sondereigentumsverwaltung',
      labelPadding: 1,
      hasSum: true,
      values: kostenRows.map(x => x.sev)
    },
    {
      label: 'Sonderkosten Eigentümer',
      title: 'z.B. Reparaturen, Instandhaltung, etc.',
      labelPadding: 1,
      hasSum: true,
      values: kostenRows.map(x => x.sonder),
      valueLabels: kostenRows.map(x => x.sonderText?.join(', '))
    },
    // {
    //   label: 'Saldo vor Auszahlung',
    //   values: saldoRows,
    //   showZeros: true
    // },
    // {
    //   label: 'Vortrag zahlungswirksamer Kosten',
    //   title: 'Kosten aus lfd Monat die erst im nächsten Monat abgerechnet werden',
    //   values: ubertragRows.map(x => (x >= EPS ? x : 0))
    // },
    {
      label: 'Zahlungswirksame Kosten aus Vormonaten',
      values: ubertragRows.map(x => (x <= EPS ? x : 0)),
      title: 'Kosten aus Vormonat, die erst jetzt abgerechnet werden'
    },
    {
      label: 'Auszahlungen',
      hasSum: true,
      values: auszRows,
      showZeros: true
    },
    {
      label: 'Saldo nach Auszahlung',
      values: auszRows.map((row, idx) => saldoRows[idx] - row),
      showZeros: true
    }
  ];

  return ownerRows;
}

// hides complete block if all values are 0
function conditionalRows(tableRows: TableRowData[], enabled = true) {
  if (!enabled) {
    return tableRows;
  }
  const hasValues = tableRows.some(x => x.values.some(y => y !== 0));

  if (!hasValues) {
    return [];
  }
  return tableRows;
}

export function createBookingRows(data: BBResult, year: number) {
  const monthRange = createMonthRange(year);

  const mieterDataStripped = data.mieterData.filter(x => {
    return (
      Object.entries(x.buckets).some(x =>
        Object.entries(x[1]).some(([k, v]) => k.startsWith(String(year)) && Math.abs(v.value) > EPS)
      ) ||
      // show tennants with open saldo
      Math.abs(x.cstart) > EPS
    );
  });

  const saldoData = mapSaldoData(mieterDataStripped, monthRange);

  const sollstMiete = monthRange.map(x => {
    const miete = getBucketVal(data.mieterBuckets, 'sollstMiete', x.dateString);
    const nk = getBucketVal(data.mieterBuckets, 'sollstNk', x.dateString);
    const tg = getBucketVal(data.mieterBuckets, 'sollstTg', x.dateString);

    return miete + nk + tg;
  });

  const mieterRows: TableRowData[] = [
    {
      label: 'Sollstellung Miete',
      values: sollstMiete,
      hasSum: true
    },
    ...mieterDataStripped.flatMap(m => {
      const mValues = monthRange.map(x => getBucketVal(m.buckets, 'sollstMiete', x.dateString));
      const nkValues = monthRange.map(x => getBucketVal(m.buckets, 'sollstNk', x.dateString));
      const stValues = monthRange.map(x => getBucketVal(m.buckets, 'sollstTg', x.dateString));
      const sumValues = sumRows([mValues, nkValues, stValues]);

      return conditionalRows([
        {
          label: formatTennantName(m.mieter.NAME),
          labelPadding: 1,
          values: sumValues,
          hasSum: true,
          valueClassName: 'border-b'
        },
        {
          label: 'Miete',
          labelPadding: 2,
          values: mValues
        },
        {
          label: 'Nebenkosten',
          labelPadding: 2,
          values: nkValues
        },
        {
          label: 'Stellplatz',
          labelPadding: 2,
          values: stValues
        }
      ]);
    }, true),
    ...conditionalRows(
      [
        {
          label: 'Sollstellung Nebenkostenabrechnung Vorj.',
          values: monthRange.map(x => getBucketVal(data.mieterBuckets, 'sollstAbr', x.dateString)),
          hasSum: true
        },
        ...mieterDataStripped.map(m => ({
          label: formatTennantName(m.mieter.NAME),
          labelPadding: 1,
          values: monthRange.map(x => {
            return getBucketVal(m.buckets, 'sollstAbr', x.dateString);
          }),
          hasSum: true
        }))
      ],
      true
    ),
    ...conditionalRows(
      [
        {
          label: 'Sonstiges/Rücklastschriften',
          values: monthRange.map(x => getBucketVal(data.mieterBuckets, 'sonstiges', x.dateString))
        },
        ...mieterDataStripped.map(m => ({
          label: formatTennantName(m.mieter.NAME),
          labelPadding: 1,
          values: monthRange.map(x => {
            return getBucketVal(m.buckets, 'sonstiges', x.dateString);
          })
        }))
      ],
      true
    ),
    {
      label: 'Zahlungen Mieter gesamt',
      values: monthRange.map(x =>
        getBucketValsSum(
          data.mieterBuckets,
          ['zahlungenMiete', 'zahlungenNk', 'zahlungenTg', 'zahlungenAbr'],
          x.dateString
        )
      ),
      hasSum: true,
      showZeros: true
    },
    ...mieterDataStripped.map(m => ({
      label: formatTennantName(m.mieter.NAME),
      labelPadding: 1,
      values: monthRange.map(x => {
        return getBucketValsSum(
          m.buckets,
          ['zahlungenMiete', 'zahlungenNk', 'zahlungenTg', 'zahlungenAbr'],
          x.dateString
        );
      }),
      hasSum: true
    })),
    {
      label: 'Fortgeschriebener Saldo Mieterkonto gesamt',
      values: monthRange.map(x => saldoData.saldoTotal[x.dateString] || 0),
      showZeros: true
    },
    ...saldoData.mieterData.map(m => ({
      label: formatTennantName(m.mieter.NAME),
      labelPadding: 1,
      values: monthRange.map(x => {
        return m.saldoData[x.dateString] || 0;
      }),
      showZeros: true
    }))
  ];

  const sonderKostenFlat: { text: string; date: Date }[] = [];
  monthRange.forEach(month => {
    const sonderkosten = data.sevData.buckets['kostenSonderkosten']?.[month.dateString];
    const sonderkostenItems = sonderkosten?.items || [];
    if (sonderkostenItems.length) {
      for (const elem of sonderkostenItems) {
        sonderKostenFlat.push({
          text: `${formatNumber(elem.BETRAG)}€ ${elem.BUCHUNGSTEXT}`,
          date: elem.BELEGDATUM
        });
      }
    }
  });

  return {
    monthRange,
    mieterRows,
    ownerRows: createOwnerRows(data, monthRange),
    sonderKostenFlat
  };
}
