export const apiUrl = process.env.API_URL ?? 'http://localhost:8090';

export class StatusError extends Error {
  statusCode: number;
  constructor(statusCode: number, message: string) {
    super(`code: ${statusCode} ${message}`);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace?.(this, this.constructor);
  }
}

export async function postApi<RequestBody extends object>(
  endPoint: string,
  body: RequestBody,
  options: RequestInit = {},
  responseType: 'json' | 'blob' = 'json'
) {
  const resp = await fetch(`${apiUrl}${endPoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    credentials: 'include',
    ...options
  });

  if (resp.ok) {
    if (responseType === 'json') {
      return await resp.json();
    } else {
      return await resp.blob();
    }
  } else {
    throw new StatusError(resp.status, await resp.text());
  }
}

export async function getApi(endPoint: string, query?: Record<string, string>) {
  let url = `${apiUrl}${endPoint}`;
  if (query) {
    const searchParams = new URLSearchParams(query);
    url += '?' + searchParams.toString();
  }

  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (resp.ok) {
    return await resp.json();
  } else {
    throw new StatusError(resp.status, await resp.text());
  }
}
