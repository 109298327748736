import { Link } from 'react-router-dom';
import EnvelopeIcon from '@heroicons/react/24/outline/EnvelopeIcon';
import PencilSquareIcon from '@heroicons/react/24/outline/PencilSquareIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import { CardWTitle } from '@/components/Layout';
import { useContactData } from '@/lib/apiEndpoints';
import { CommunicationPreference, ContactData } from '@/shared/types';
import { ContactLabel } from '../ContactLabel';
import LoadingPlaceholder from '../LoadingPlaceholder';
import Tooltip from '../Tooltip';

function mapCommunicationPreference(communicationPreference: CommunicationPreference) {
  if (communicationPreference === 'letter') {
    return 'Post';
  } else if (communicationPreference === 'mail') {
    return 'E-Mail';
  } else {
    return 'unbekannt';
  }
}

function ContactDisplay({ contact }: { contact: ContactData }) {
  return (
    <div className="flex flex-col">
      <div className="mb-1 font-bold text-gray-600">
        <ContactLabel contact={contact} />
      </div>
      <div className="mb-4 text-sm">
        <span className="text-gray-600">Kundennummer: </span>
        <span>{contact.login}</span>
      </div>
      <div className="mb-1 flex items-center">
        <PhoneIcon title="Telefon" className="mr-2 inline-block h-4 w-4 align-top text-gray-600" />
        {[contact.phone, contact.phone2, contact.mobile].filter(Boolean).join(', ')}
      </div>
      <div className="flex items-center">
        <EnvelopeIcon title="Email" className="mr-2 inline-block h-4 w-4 align-top text-gray-600" />
        {contact.email}
      </div>
    </div>
  );
}

export default function Contact({ className }: { className?: string }) {
  const { data: contact } = useContactData();

  const titleElement = (
    <span>
      Kontaktdaten
      <Tooltip title="Kontaktdaten ändern">
        <Link
          to="/kontakt"
          className="inline-block align-bottom ml-2 p-1 rounded-full shadow-md transition-transform hover:scale-125 bg-gray-600 text-white"
        >
          <PencilSquareIcon className="h-5 w-5" />
        </Link>
      </Tooltip>
    </span>
  );

  return (
    <CardWTitle className={className} title={titleElement}>
      {contact ? <ContactDisplay contact={contact} /> : <LoadingPlaceholder />}
    </CardWTitle>
  );
}
