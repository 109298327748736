import { Fragment, memo } from 'react';

export const ContactLabel = memo(function ContactLabel({
  contact,
  className,
  limit = 1
}: {
  contact: { label: string; extra?: string | null };
  className?: string;
  limit?: number;
}) {
  let extraSplit = contact.extra?.split('\n');
  if (extraSplit && limit) {
    extraSplit = extraSplit.slice(0, limit);
  }

  return (
    <span className={className}>
      {contact.label}
      {extraSplit &&
        extraSplit.length > 0 &&
        extraSplit.map((line, i) => (
          <Fragment key={i}>
            <br />
            {line}
          </Fragment>
        ))}
    </span>
  );
});
