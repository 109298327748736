import React, { useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import XIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useTransition } from '@/lib/hooks';
import { joinClasses } from '@/lib/utils';
import { Button } from './Layout';

const FOCUSABLE_SELECTOR = ['input', 'button', 'textarea']
  .map(x => `${x}[data-autofocus]`)
  .join(', ');
export function focusFirstElement(elem: HTMLElement) {
  const walker = document.createTreeWalker(elem, NodeFilter.SHOW_ELEMENT);

  let currentNode: Node | null = walker.currentNode;

  while (currentNode) {
    if ((currentNode as HTMLElement).matches(FOCUSABLE_SELECTOR)) {
      (currentNode as HTMLElement).focus();
      return;
    }
    currentNode = walker.nextNode();
  }
}

function ButtonItem({
  children,
  onClick,
  selected = false,
  className
}: {
  children: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
  className?: string;
}) {
  return (
    <Menu.Item as="div" onClick={onClick}>
      {({ active }) => (
        <button
          type="button"
          className={joinClasses(
            'w-full p-2 text-center text-sm',
            selected ? 'bg-primary-700 text-white' : 'bg-gray-200 text-gray-800',
            active && 'ring-2 ring-yellow-400',
            className
          )}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  );
}

Dropdown.Item = Menu.Item;
Dropdown.ButtonItem = ButtonItem;
Dropdown.Button = Menu.Button;

function DefaultTransition({ children }: { children: React.ReactNode }) {
  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-75"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-75"
    >
      {children}
    </Transition>
  );
}

export function Dropdown({
  button,
  children,
  itemsClassName,
  position = 'right'
}: {
  button: ({ open }: { open: boolean }) => React.ReactElement;
  children: React.ReactNode;
  itemsClassName?: string;
  position?: 'left' | 'right';
}) {
  return (
    <Menu as="div" className="flex flex-col">
      {({ open }) => (
        <>
          {button({ open })}
          <div className="relative z-40">
            <DefaultTransition>
              <Menu.Items
                className={joinClasses(
                  'absolute top-0 z-50 mt-2 origin-top space-y-1 border border-gray-400 rounded-sm bg-gray-50 p-2 shadow-md',
                  position === 'left' ? 'right-0' : 'left-0',
                  itemsClassName
                )}
              >
                {children}
              </Menu.Items>
            </DefaultTransition>
          </div>
        </>
      )}
    </Menu>
  );
}

export type PopoverButtonProps = {
  setIsOpen: React.Dispatch<boolean>;
  toggleOpen: () => void;
  isOpen: boolean;
};

interface PopoverProps {
  button: (p: PopoverButtonProps) => React.ReactNode;
  initialOpen?: boolean;
  children: React.ReactNode;
  showClose?: boolean;
}

Popover.DefaultButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(function DefaultButton({ children, className, ...props }, ref) {
  return (
    <Button
      ref={ref}
      {...props}
      className={joinClasses('flex items-center justify-between pr-1', className)}
    >
      <div>{children}</div>
      <div className="ml-2">
        <ChevronDownIcon className="h-4 w-4" />
      </div>
    </Button>
  );
});

export function PopoverControlled({
  button,
  children,
  isOpen,
  setIsOpen,
  showClose = false
}: PopoverProps & { isOpen: boolean; setIsOpen: React.Dispatch<boolean> }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    window.requestAnimationFrame(() => {
      focusFirstElement(popupRef.current!);
    });

    const keyListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    const clickListener = (e: MouseEvent) => {
      if (!containerRef.current!.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', keyListener);
    window.addEventListener('mousedown', clickListener, true);
    return () => {
      window.removeEventListener('keydown', keyListener);
      window.removeEventListener('mousedown', clickListener, true);
    };
  }, [isOpen, setIsOpen]);

  const { shouldRender, transitionClass } = useTransition({
    isOpen,
    ref: popupRef,
    fromClass: 'scale-75 opacity-0',
    toClass: 'scale-100 opacity-100',
    enterClass: 'ease-in duration-75',
    exitClass: 'ease-out duration-100'
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div ref={containerRef} className="inline-block">
      {button({ isOpen, setIsOpen, toggleOpen })}
      {shouldRender && (
        <div className="relative">
          <div
            role="listbox"
            className={joinClasses(
              'absolute left-0 top-1 z-50 origin-top rounded-sm bg-white p-2 shadow-lg ring-1 ring-black/20 transition-all',
              transitionClass
            )}
            ref={popupRef}
          >
            {children}
            {showClose && (
              <button
                className="absolute right-1 top-1"
                title="close"
                onClick={() => setIsOpen(false)}
              >
                <XIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function Popover({ button, children, initialOpen = false, showClose }: PopoverProps) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <PopoverControlled
      button={button}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      initialOpen={initialOpen}
      showClose={showClose}
    >
      {children}
    </PopoverControlled>
  );
}
