import { useMemo, useState } from 'react';
import {
  CollapsePanel,
  CollapsePanelButton,
  CollapsePanelContent
} from '@/components/CollapsePanel';
import { buildDownloadLink } from '@/lib/apiEndpoints';
import { formatDate } from '@/lib/dateUtils';
import { groupFilesByDate, formatDoc } from '@/lib/fileUtils';
import { FileI } from '@/shared/types';

function Document({ doc, showObjects = true }: { doc: FileI; showObjects?: boolean }) {
  const { title, subtitle } = useMemo(() => formatDoc(doc, { showObjects }), [doc, showObjects]);

  return (
    <span className="flex flex-col md:flex-row md:items-center md:justify-between md:gap-x-2">
      <span className="shrink-0 text-sm text-gray-400 md:order-last">{formatDate(doc.date!)}</span>
      {subtitle ? (
        <span>
          {title} - {subtitle}
        </span>
      ) : (
        <span>{title}</span>
      )}
    </span>
  );
}

const defaultChild = (elem: FileI) => <Document doc={elem} />;

function docTitle(doc: FileI) {
  const titleParts: string[] = [];
  if (doc.object) {
    titleParts.push(String(doc.object.number));
    if (doc.unit) {
      titleParts.push(String(doc.unit.number));
    }
  }
  if (doc.location_) {
    titleParts.push(doc.location_ + '/' + doc.filename);
  }

  return titleParts.join(' - ');
}

export function DocumentsList({
  items,
  children = defaultChild
}: {
  items: FileI[];
  children?: (elem: FileI) => React.ReactNode;
}) {
  return (
    <ul className="divide-y">
      {items.map(doc => (
        <li key={doc.id ?? doc.filename} className="border-dashed border-gray-300 py-2 pr-1">
          <a
            target="_blank"
            rel="noreferrer"
            title={docTitle(doc)}
            href={buildDownloadLink(doc.filename, doc.location)}
            download
            className="block break-all border-l-2 border-primary-400 pl-2"
          >
            {children(doc)}
          </a>
        </li>
      ))}
    </ul>
  );
}

export function CollapsePanelList<T>({
  buckets,
  children,
  initialOpen = true
}: {
  buckets: { key: string; label: string; items: T[] }[];
  children: (items: T[]) => React.ReactNode;
  initialOpen?: boolean;
}) {
  const [openKey, setOpenKey] = useState<string | null>(
    initialOpen ? buckets[0]?.key ?? null : null
  );
  const toggleKey = (key: string) => {
    setOpenKey(key === openKey ? null : key);
  };

  return (
    <div className="flex flex-col flex-wrap">
      {buckets.map((bucket, idx) => {
        const isOpen = bucket.key === openKey;
        const belowOpen = buckets[idx + 1] ? buckets[idx + 1].key === openKey : false;
        return (
          <CollapsePanelContent key={bucket.key} isOpen={isOpen} belowOpen={belowOpen}>
            <CollapsePanelButton
              isOpen={isOpen}
              id={bucket.key}
              onClick={() => toggleKey(bucket.key)}
            >
              {bucket.label}
            </CollapsePanelButton>

            <CollapsePanel isOpen={isOpen}>{children(bucket.items)}</CollapsePanel>
          </CollapsePanelContent>
        );
      })}
    </div>
  );
}

export function DocsListByDate({
  data,
  children = items => <DocumentsList items={items} />
}: {
  data: FileI[];
  children?: (items: FileI[]) => React.ReactNode;
}) {
  const buckets = useMemo(() => groupFilesByDate(data), [data]).map(x => ({
    ...x,
    label: x.key
  }));

  return <CollapsePanelList buckets={buckets}>{items => children(items)}</CollapsePanelList>;
}
