import { postApi } from './api';

// prevent accidential api spamming
let errorsLogged = 0;

function logErrorToAPI(handlerName: string, error: Error) {
  if (errorsLogged++ < 100) {
    postApi('/api/error', { handlerName, stack: error.stack, url: window.document.URL }).catch(
      () => {
        // this error should be ignored
      }
    );
  }
}

export function initErrorLogger() {
  window.addEventListener('error', event => {
    logErrorToAPI('error', event.error);
  });

  window.addEventListener('unhandledrejection', event => {
    logErrorToAPI('error', event.reason);
  });
}
