import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '@/lib/api';
import { toast } from './toast';

export function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logoutMutation = useMutation(
    async () => {
      await postApi('/api/logout', {});
    },
    {
      onSuccess: () => {
        // flush sync needed so that the navigation happened before the query cache is invalidated
        flushSync(() => {
          navigate('/login');
        });
        queryClient.invalidateQueries();
        queryClient.getQueryCache().clear();
      },
      onError: err => {
        console.error(err);
        toast('Fehler beim ausloggen', 'error');
      }
    }
  );

  return () => {
    logoutMutation.mutate();
  };
}
