/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useRef, useState } from 'react';

export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);
  const handler = useCallback(() => {
    const res = window.matchMedia(query);
    setMatches(res.matches);
  }, [query]);

  useWindowListener('resize', handler);
  useEffect(handler, [handler]);
  return matches;
}

// tailwind breakpoints
const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
};

export function useBreakpoint(bp: 'sm' | 'md' | 'lg' | 'xl' | '2xl') {
  return useMatchMedia(`(min-width: ${BREAKPOINTS[bp]}px)`);
}

export function useListener(
  target: React.RefObject<EventSource>,
  action: string,
  handler: EventListener,
  useCapture = false
) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const callbackHandler = useCallback((e: Event) => {
    return handlerRef.current(e);
  }, []);

  useEffect(() => {
    const _target = target.current!;
    _target.addEventListener(action, callbackHandler, useCapture);
    return () => {
      _target.removeEventListener(action, callbackHandler, useCapture);
    };
  }, [action, target, callbackHandler, useCapture]);
}

export function useWindowListener<T extends keyof WindowEventMap>(
  action: T,
  handler: (ev: WindowEventMap[T]) => void,
  useCapture = false
) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  useEffect(() => {
    const callbackHandler = (e: WindowEventMap[T]) => {
      return handlerRef.current(e);
    };

    window.addEventListener(action, callbackHandler, useCapture);
    return () => {
      window.removeEventListener(action, callbackHandler, useCapture);
    };
  }, [action, useCapture]);
}

export function useTransition({
  isOpen,
  ref,
  fromClass,
  toClass,
  enterClass,
  exitClass
}: {
  isOpen: boolean;
  ref: React.RefObject<HTMLElement>;
  fromClass: string;
  toClass: string;
  enterClass: string;
  exitClass: string;
}) {
  const [isInserted, setInserted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setInserted(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && ref.current) {
      const listener = () => {
        setInserted(false);
      };
      const elem = ref.current;
      elem.addEventListener('transitionend', listener);
      return () => elem.removeEventListener('transitionend', listener);
    }
  }, [isOpen, ref]);

  const isEnter = isOpen && !isInserted;
  const isExit = !isOpen && isInserted;
  const shouldRender = isOpen || isInserted;
  const transitionClass = `${isEnter || isExit ? fromClass : toClass} ${
    isExit ? exitClass : enterClass
  }`;

  return { isEnter, isExit, shouldRender, transitionClass };
}

export function useIdentity<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export function useConstructor(effect: React.EffectCallback) {
  const hasRun = useRef(false);
  const destructorRef = useRef<ReturnType<React.EffectCallback>>();

  if (!hasRun.current) {
    hasRun.current = true;
    destructorRef.current = effect();
  }

  useEffect(() => () => {
    if (destructorRef.current) {
      destructorRef.current();
    }
  });
}

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): readonly [T, (x: T) => void] {
  const loadLocalstorageState = () => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        console.error('Error parsing localstorage value', err);
        return defaultValue;
      }
    }
    return defaultValue;
  };

  const [state, _setState] = useState<T>(loadLocalstorageState);

  const setState = useCallback(
    (newState: T) => {
      _setState(newState);
      localStorage.setItem(key, JSON.stringify(newState));
    },
    [key, _setState]
  );

  return [state, setState] as const;
}

export function useResizeObserver(ref: React.RefObject<HTMLElement>, cb: ResizeObserverCallback) {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const observer = useRef<ResizeObserver>();

  useEffect(() => {
    if (ref.current) {
      observer.current = new ResizeObserver(cbRef.current);
      observer.current.observe(ref.current);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref]);
}

export function useDebounce<T>(value: T, ms: number) {
  const [dValue, setDvalue] = useState(value);
  const valRef = useRef(value);

  useEffect(() => {
    if (valRef.current !== value) {
      valRef.current = value;
      const timeoutId = window.setTimeout(() => {
        setDvalue(value);
      }, ms);
      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [value, ms]);

  return dValue;
}
